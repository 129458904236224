.achievements-carousel {
  .col {
    text-align: center;
  }
  b {
    display: block;
    font-weight: 800;
    font-size: 90px;
    @include xl {
      font-size: 120px;
    }
  }
}
