.link-refresh {
  position: relative;
  padding-left: 28px;
  font-size: 14px;
  font-weight: 600;

  &:hover, &:active, &:focus {
    text-decoration: none;
  }

  &:before {
    display: block;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 16px;
    height: 16px;
    background: url(/i/icons/reload.svg) no-repeat 0 center / 16px 16px;
    transition: transform 0.3s;
  }
  &:hover:before {
    transform: rotate(180deg);
  }
}
