.subscribe-block {
  margin: 4rem 0 0;
  padding: 4rem 0;
  background-color: #00aeef;
  color: $textColor;
  text-align: center;

  h2 {
    max-width: 502px;
    margin: 0 auto 2rem;
    @include fs(20px, $md: 24px);
  }

  p {
    max-width: 502px;
    margin: 1rem auto 0;
  }
  a {
    color: #000;
    font-weight: 500;
  }

  input[type="text"], input[type="email"] {
    max-width: 562px;
    width: 100%;
    background: transparent;
    border: 2px solid #00bdf8;
    border-radius: 6px;
    padding: 22px 55px 22px 22px;
    color: #fff;
    &:placeholder {
      color: #00bdf8;
    }
  }
  button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent;
    border: 0 none;
    padding: 22px 12px;
    margin: 0 0 0 -55px;
    line-height: inherit;
    cursor: pointer;
  }
}
