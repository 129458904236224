html {
  overflow-y: scroll;
  height: 100%;
  font-size: 14px;
  letter-spacing: 0.2px;
  @include md {
    font-size: 16px;
    letter-spacing: normal;
  }
  @include xl {
    font-size: 18px;
  }
}
body {
  height: 100%;
  margin: 0;
  font-weight: normal;
  font-family: $baseFontFamily;
  color: $textColor;
}

address {
  font-style: normal;
}
abbr.unhinted {
  text-decoration: none;
  border: 0 none;
  cursor: help;
}

h1 {
  margin: 3rem 0 1rem;
  @include sm {
    margin: 5rem 0;
  }
  font-weight: 800;
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: 0.4px;
  @include sm {
    font-size: 2.667rem;
    line-height: 1.2;
    letter-spacing: normal;
  }
  @include lg {
    max-width: 832px;
    line-height: 1.2;
  }
}
h2 {
  max-width: 416px;
  font-weight: 800;
  font-size: 20px;
  @include sm {
    font-size: 1.778rem;
  }
}
h3 {
  font-weight: 800;
  font-size: 18px;
  @include sm {
    font-size: 1.334rem;
  }
}
p {
  line-height: 1.43;
  @include md {
    line-height: 1.56;
  }
}


.hidden {
  display: none;
}

.email, .phone, .nowrap {
  white-space: nowrap;
}

img.emoji {
  display: inline-block;
  width: 1.2em;
  height: 1.2em;
}

a {
  color: $linkColor;
  &:hover, &:active, &:focus {
    color: lighten($linkColor, 15%);
    text-decoration: none;
  }
}

.btn-primary {
  background-color: lighten($themeColor, 20%);
  color: #fff;
  border-color: transparent;
  font-size: 1rem;
  font-weight: 600;
  &:hover, &:active, &:focus, &:not(:disabled):not(.disabled).active {
    background-color: $themeColor;
    color: #fff;
    border-color: transparent;
  }
  &.btn-lg {
    padding: 1rem;
  }
}
.btn-outline-primary {
  background-color: lighten($themeColor, 48%);
  color: $themeColor;
  border-color: transparent;
  font-size: 1rem;
  font-weight: 600;
  &:hover, &:active, &:focus, &:not(:disabled):not(.disabled).active {
    background-color: $themeColor;
    color: #fff;
    border-color: transparent;
    path {
      fill: #fff;
    }
  }
}
.btn-group-toggle {
  .btn-outline-primary {
    background-color: transparent;
    border: 2px solid $themeColor;
    border-left-width: 0;
    color: $themeColor;
    path {
      fill: $themeColor;
    }
    &:first-child {
      border-left-width: 2px;
    }
    &.focus {
      box-shadow: none;
    }
  }
}

.md-br {
  @include md {
    width: 100%;
  }
}
.vs {
  width: 1px;
  height: auto;
  background-color: $separatorColor;
}
.d-touch-none {
  @media(hover: none) {
    display: none;
  }
}
.d-touch-inline-block {
  display: none;
  @media(hover: none) {
    display: inline-block;
  }
}

.carousel-control-prev,
.carousel-control-next {
  width: 10px;
  @include sm {
    width: 40px;
  }
  opacity: 1;
  &:hover {
    opacity: .5;
    @media(hover: none) {
      opacity: 1;
    }
  }
  svg {
    width: 10px;
    height: 30px;
    path {
      stroke-width: 8;
    }
    @include sm {
      width: 40px;
      height: 80px;
      path {
        stroke-width: 2;
      }
    }
  }
}
.carousel-control-prev {
  left: -35px;
  @include sm {
    left: -50px;
  }
}
.carousel-control-next {
  right: -35px;
  @include sm {
    right: -50px;
  }
}
.carousel-indicators {
  bottom: -4rem;
  li {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 1px solid $themeColor;
    background: transparent;
    &.active {
      border-color: transparent;
      background: $themeColor;
    }
    &::before {
      display: none;
    }
    &::after {
      display: none;
    }
  }
}

.clients-list {
  img {
    width: auto;
    height: auto;
  }
  svg, img {
    margin: 0 auto;
    display: block;
    max-width: 80px;
    max-height: 45px;
    @include md {
      max-width: 90px;
      max-height: 52px;
    }
    @include lg {
      max-width: 100px;
      max-height: 62px;
    }
    @include lg {
      max-width: 120px;
      max-height: 80px;
    }
  }
}

ul.custom-bullets {
  list-style: none;
  padding: 0;
  li {
    position: relative;
    padding-left: 1rem;
    margin-top: 1rem;
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      content: "●";
      color: #00aeef;
      font-weight: bold;
      margin-right: 1rem;
    }
  }
}

.lines-bg {
  @include sm {
    .bg1, .bg2, .bg3 {
      position: fixed;
      z-index: 0;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      &::before, &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        width: 1px;
        background: #f5f5f5;
      }
    }
    .bg1::before { left: 28%; }
    .bg1::after  { left: 72%; }
    .bg2::before { left: 39%; }
    .bg2::after  { left: 61%; }
    .bg3::before { left: 50%; }
    .bg3::after  { display: none; }
  }
}
