@import url(https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,700,800,900&subset=cyrillic);

@import "base/variables";
@import "base/mixins";
@import "base/shared";

@import "base/header";
@import "base/footer";

@import "components/achievements_carousel.scss";
@import "components/hamburger.scss";
@import "components/link_refresh.scss";
@import "components/link_with_arrow.scss";
@import "components/speechbubble.scss";
@import "components/subscribe_block.scss";
@import "pages/about.scss";
@import "pages/contacts.scss";
@import "pages/education.scss";
@import "pages/error.scss";
@import "pages/events.scss";
@import "pages/faq.scss";
@import "pages/main.scss";
@import "pages/portfolio.scss";
@import "pages/process.scss";
@import "pages/vacancies.scss";
