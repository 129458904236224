.process-page {
  h1 {
    max-width: 832px;
  }
  h2 {
    max-width: none;
  }
}

.process-items-block {
  margin-top: 3rem;
  padding: 7rem 0 4rem;
  background: #ebf9fe;

  .container {
    position: relative;
  }
  .speechbubble {
    margin: -9rem 0 4rem;
    @include sm {
      margin: 0;
      position: absolute;
      top: -10rem;
      right: 0;
    }
  }
}

.process-list {
  li {
    margin-bottom: 3rem;
  }
  a {
    display: block;
    padding-top: 72px;
    text-align: center;
    font-weight: 600;
    &::before {
      display: block;
      content: "";
      position: absolute;
      left: 50%;
      top: 0;
      width: 68px;
      height: 64px;
      transform: translateX(-50%);
      background: transparent no-repeat 0 0 / 64px 64px;
    }
    &:hover {
      text-decoration: none;
      opacity: .6;
    }
  }
  @each $cls in analytics, prototyping, design, development, testing, support {
    .#{$cls}::before {
      background-image: url(/i/icons/#{$cls}.svg);
    }
  }
}

.process-descriptions-block {
  article {
    padding: 2rem 0;
  }
  .big-number {
    margin-right: 4rem;
    @include fs(24px, $md: 120px);
    font-weight: 800;
  }
  h2 {
    margin: 6px 0 1rem;
    @include md {
      margin: 4rem 0 1rem;
    }
  }
  .related-projects {
    margin: 4rem 0 0;
  }
  .project-image {
    img {
      border-radius: 30px;
      transition: all 0.3s;
      &:hover {
        border-radius: 15px;
      }
    }
  }
  .project-meta {
    display: none;
  }
  .link-with-arrow {
    color: #000;
    font-weight: normal;
  }
}

.additional-services-block {
  padding: 4rem 0;
  background: $mutedBgColor;

  h2 {
    @include fs(24px, 32px, 40px, 40px, 48px);
    margin-bottom: 3rem;
  }
  b {
    font-weight: 600;
  }
}

.contact-us-block {
  padding: 4rem 0;
  h2 {
    font-size: 24px;
  }
}
