.main-page {
  h1 {
    @include xl {
      font-size: 72px;
    }
  }
  h2 {
    @include xl {
      font-size: 48px;
    }
    margin-bottom: 1rem;
    @include sm {
      margin-bottom: 3rem;
    }
  }
  .speechbubble {
    @include lg {
      width: 416px;
    }
    @include sm {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}

.main-section {
  position: relative;
  .container {
    position: relative;
  }
}

.main-title-block {
  padding: 2rem 0;
  @include sm {
    padding: 12rem 0 4rem;
  }
  @include lg {
    padding: 10rem 0 4rem;
  }
  h1 {
    margin: 0;
    @include lg {
      max-width: 70%;
    }
    @include xl {
      max-width: 1040px;
    }
  }
  .speechbubble {
    @include sm {
      top: -11rem;
    }
    @include lg {
      top: -8rem;
    }
  }
}
.verified-by {
  text-align: center;
  margin-top: 2rem;
  @include sm {
    margin-top: -3rem;
  }
  @include lg {
    margin-top: 0;
  }
  @include xl {
    margin-top: 2rem;
  }
  p {
    float: left;
    writing-mode: sideways-lr;
    margin: 1rem 0 0 0;
    @include sm {
      float: none;
      writing-mode: horizontal-tb;
      margin: 0 0 1rem 0;
    }
  }
  img {
    max-width: 100px;
    @include lg {
      max-width: 120px;
    }
    @include xl {
      max-width: 160px;
    }
  }
}

.main-projects-block {
  padding: 2rem 0;
  .projects-list-wrapper {
    overflow: hidden;
    height: 176px;
    @include sm {
      height: 224px;
    }
    @include lg {
      height: 304px;
    }
    @include xl {
      height: 416px;
    }
  }
  .projects-list {
    overflow-y: hidden;
    overflow-x: scroll;
    white-space: nowrap;
  }
  .projects-list-item {
    display: inline-block;
    white-space: normal;
    width:  176px;
    height: 176px;
    @include sm {
      width:  224px;
      height: 224px;
    }
    @include lg {
      width:  304px;
      height: 304px;
    }
    @include xl {
      width:  416px;
      height: 416px;
    }
  }
}

.main-process-block {
  padding: 2rem 0 4rem;
  h2 {
    @include sm {
      max-width: 50%;
    }
    @include md {
      width: 416px;
    }
  }
  .speechbubble {
    width: auto;
    @include sm {
      width: 50%;
      top: -2rem;
    }
    @include md {
      width: 356px;
    }
    @include lg {
      width: auto;
    }
  }
  .main-process-list {
    li {
      margin: 1rem 0;
    }
    a {
      text-align: left;
      margin-bottom: 1rem;
      font-size: 20px;
      @include md {
        font-size: 24px;
      }
      font-weight: bold;
      color: $linkColor;
      &::before {
        left: 0;
        transform: translateX(8px);
      }
    }
  }
}

.main-achievements-block {
  padding: 2rem 0;
  @include sm {
    padding: 10rem 0 8rem;
  }
  margin-bottom: 2rem;
  @include sm {
    margin-bottom: 4rem;
  }
  background: url(/i/bg/achievements.svg) no-repeat center center / contain;
  .speechbubble {
    margin: -2rem 0 2rem;
    @include sm {
      margin: 0;
      top: -10rem;
    }
  }
  .carousel {
    margin: 0 2rem 4rem;
    @include md {
      margin: 0 5rem;
    }
  }
  .carousel-inner {
    min-height: 250px;
    @include md {
      min-height: 340px;
    }
  }
}

.main-clients-block {
  padding: 6rem 0 2rem;
  background-color: $mutedBgColor;
  .speechbubble {
    margin-top: -2rem;
    @include sm {
      top: -9rem;
      margin-top: 0;
    }
    max-width: 100%;
    width: 624px;
  }
  .clients-list {
    path {
      fill: #000;
    }
  }
  .testimonial-bubble {
    border-radius: 40px;
    border-bottom-left-radius: 0;
    border: solid 1px $mutedBgColor;
    margin: 0;
    @include md {
      height: 262px;
      margin: 32px;
      box-shadow: 8px 8px 32px 0 rgba(0, 0, 0, 0.1);
    }
    padding: 40px;
    background-color: #ffffff;
  }
  .testimonial-text {
    position: relative;
    max-height: 7.6rem;
    overflow: hidden;
    margin-bottom: 1rem;
    &:after {
      content: " ";
      text-align: right;
      position: absolute;
      bottom: 0;
      right: 0;
      width: 70%;
      line-height: 1.43;
      @include md {
        line-height: 1.56;
      }
      background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%);
    }
  }
  .testimonial-author {
    font-size: 14px;
    text-align: center;
    @include md {
      max-width: 70%;
      margin: 0 32px;
      text-align: left;
    }
    img {
      border-radius: 50%;
    }
    .author-image {
      display: block;
      margin: 0 0 1rem;
    }
    .name {
      font-weight: bold;
    }
  }
  .carousel {
    margin: 0;
    @include md {
      margin: 0 5rem;
    }
  }
  .carousel-control-prev {
    top: 50px;
    bottom: auto;
    left: 10%;
    svg {
      width: 20px;
      height: 40px;
    }
    @include md {
      top: 0;
      bottom: 0;
      left: -15%;
      svg {
        width: auto;
        height: auto;
      }
    }
  }
  .carousel-control-next {
    top: 50px;
    bottom: auto;
    right: 10%;
    svg {
      width: 20px;
      height: 40px;
    }
    @include md {
      top: 0;
      bottom: 0;
      right: -15%;
      svg {
        width: auto;
        height: auto;
      }
    }
  }
  .link-with-arrow {
    margin: 1rem 0 4rem 0;
    @include md {
      position: relative;
      margin: -3rem 0 7rem auto;
    }
  }
}

.main-events-block {
  padding: 6rem 0;
  background-color: $altBgColor;
  color: #fff;
  .speechbubble {
    margin: -10rem 0 2rem;
    @include sm {
      top: -10rem;
      margin: 0;
    }
  }
  .event-date {
    margin: 3rem 0 2rem;
  }
}

.main-education-block {
  padding: 6rem 0;
  .speechbubble {
    margin: -10rem 0 2rem;
    @include sm {
      top: -10rem;
      margin: 0;
    }
  }
  .embed-responsive {
    max-width: 832px;
  }
  .course-providers {
    margin: 6rem auto 2rem;
  }
}

.main-faq-block {
  padding: 6rem 0;
  background-color: #00aeef;
  h2 {
    max-width: none;
  }
  a {
    color: #fff;
    &:hover, &:active, &:focus {
      color: #fff;
    }
  }
  path {
    stroke: #fff;
  }
  .main-faq-list {
    margin-top: 2rem;
    max-width: 832px;
  }
}
