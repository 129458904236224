.portfolio-page {
  h1 {
    margin-bottom: 2.5rem;
  }
  .projects-list {
    margin: 4rem 0;
  }
}

.projects-tags {
  line-height: 2.5;
}

.period-select-wrapper {
  position: relative;
}
.period-select-label {
  font-weight: bold;
  color: $themeColor;
  cursor: pointer;
  white-space: nowrap;
  &::after {
    content: "";
    display: inline-block;
    vertical-align: middle;
    margin-left: 8px;
    background: str-replace(url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="10" viewBox="0 0 16 10"><path fill="#00AEEF" d="M 0 0 l 8 10 8 -10 z"/></svg>'), '#', '%23') no-repeat 100% 50% / cover;
    width: 16px;
    height: 10px;
    transition: all .5s;
  }
  &.active::after {
    transform: rotate(180deg);
  }
}
.period-select-default-dropdown {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
  opacity: 0;
}
.period-select-custom-dropdown {
  position: absolute;
  top: 3rem;
  left: 0;
  z-index: 3;
  padding: 1rem 0;
  border-radius: 16px;
  box-shadow: 8px 8px 32px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border: solid 1px #f5f5f5;
  font-size: 18px;
  font-weight: 600;
  color: $linkColor;
  div {
    padding: .5rem 1rem;
    cursor: pointer;
    &:hover {
      background: #e8f9ff;
    }
  }
}


.projects-list {
  margin: 0;

  .projects-list-item {
    padding: 0;
  }
  .call-to-action {
    min-height: 126px;
  }
  .link-with-arrow {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    max-width: none;
    align-items: center;
    text-decoration: none;
    padding: 0;
    @include md {
      padding: 48px;
    }
    margin: 0;
    color: #000;
    font-weight: normal;
  }

  .project-preview {
    position: relative;
    .project-meta {
      display: none;
    }
    &:hover {
      .project-meta {
        display: block;
      }
    }
  }
  .project-meta {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.6);
    font-size: 1.3rem;
    @include md {
      font-size: 2rem;
    }
    color: $linkColor;
    small {
      display: block;
      color: white;
      font-size: 1rem;
    }
  }
  .project-meta-inner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 24px;
    a {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
}
