.faq {
  background: #e8f9ff;
  padding: 2rem 0 4rem;

  .faq-item {
    position: relative;
    margin-bottom: .5rem;
    @include md {
      margin-bottom: 2rem;
    }
    padding: 1rem 2.2rem .5rem;
    @include md {
      padding: 2rem 8rem .5rem;
    }
    border: 1px solid transparent;
    border-radius: 16px;
    cursor: pointer;

    &::before {
      display: block;
      content: "";
      position: absolute;
      left: .2rem;
      top: .8rem;
      @include md {
        left: 4rem;
        top: 2rem;
      }
      width: 24px;
      height: 24px;
      background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij4NCiAgPHBhdGggZmlsbD0ibm9uZSIgc3Ryb2tlPSIjMDBhZWVmIiBzdHJva2Utd2lkdGg9IjIiIGQ9Ik03IDExbDUgNCA1LTQiLz4NCjwvc3ZnPg0K') no-repeat 0 0 / 24px 24px;
      transition: all 0.3s;
    }

    &.show {
      background: #ffffff;
      border-color: $mutedBgColor;
      box-shadow: 8px 8px 32px 0 rgba(0, 0, 0, .1);
      &::before {
        transform: rotate(180deg);
      }
    }
  }

  .faq-question {
    max-width: none;
    margin-bottom: 1rem;
    font-size: 1rem;
    font-weight: 600;
    @include md {
      font-size: 24px;
      font-weight: 800;
    }
  }
}
