.link-with-arrow {
  display: block;
  max-width: 250px;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;

  margin-top: 1rem;
  @include sm {
    margin-top: 48px;
  }

  em {
    color: $themeColor;
    font-weight: 600;
    font-style: normal;
  }
  path {
    stroke: #00aeef;
  }
  svg {
    display: none;
    @include sm {
      display: block;
    }
    width: 72px;
    height: 16px;
    margin-bottom: 1rem;
    transition: width 0.5s;
    .shaft, .end {
      transform-origin: center center;
      transition: transform 0.5s;
    }
  }
  &:hover {
    color: $themeColor;
    svg {
      width: 108px;
      .shaft {
        transform: scaleX(1.5);
      }
      .end {
        transform: translateX(18px);
      }
    }
  }
}
