$baseFontFamily: "Montserrat", Arial, Helvetica, sans-serif;

$mutedBgColor: #f5f5f5;
$altBgColor: #1c273a;

$textColor: #1c273a;
$themeColor: #00aeef;
$linkColor: $themeColor;
$themeColorMuted: #66d7fb;

$separatorColor: rgba(28, 39, 58, .1);

@function str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}
