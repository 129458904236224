@charset "UTF-8";
@import url(https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,700,800,900&subset=cyrillic);
html {
  overflow-y: scroll;
  height: 100%;
  font-size: 14px;
  letter-spacing: 0.2px;
}

@media (min-width: 768px) {
  html {
    font-size: 16px;
    letter-spacing: normal;
  }
}

@media (min-width: 1200px) {
  html {
    font-size: 18px;
  }
}

body {
  height: 100%;
  margin: 0;
  font-weight: normal;
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  color: #1c273a;
}

address {
  font-style: normal;
}

abbr.unhinted {
  text-decoration: none;
  border: 0 none;
  cursor: help;
}

h1 {
  margin: 3rem 0 1rem;
  font-weight: 800;
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: 0.4px;
}

@media (min-width: 576px) {
  h1 {
    margin: 5rem 0;
  }
}

@media (min-width: 576px) {
  h1 {
    font-size: 2.667rem;
    line-height: 1.2;
    letter-spacing: normal;
  }
}

@media (min-width: 992px) {
  h1 {
    max-width: 832px;
    line-height: 1.2;
  }
}

h2 {
  max-width: 416px;
  font-weight: 800;
  font-size: 20px;
}

@media (min-width: 576px) {
  h2 {
    font-size: 1.778rem;
  }
}

h3 {
  font-weight: 800;
  font-size: 18px;
}

@media (min-width: 576px) {
  h3 {
    font-size: 1.334rem;
  }
}

p {
  line-height: 1.43;
}

@media (min-width: 768px) {
  p {
    line-height: 1.56;
  }
}

.hidden {
  display: none;
}

.email, .phone, .nowrap {
  white-space: nowrap;
}

img.emoji {
  display: inline-block;
  width: 1.2em;
  height: 1.2em;
}

a {
  color: #00aeef;
}

a:hover, a:active, a:focus {
  color: #3dcaff;
  text-decoration: none;
}

.btn-primary {
  background-color: #56d1ff;
  color: #fff;
  border-color: transparent;
  font-size: 1rem;
  font-weight: 600;
}

.btn-primary:hover, .btn-primary:active, .btn-primary:focus, .btn-primary:not(:disabled):not(.disabled).active {
  background-color: #00aeef;
  color: #fff;
  border-color: transparent;
}

.btn-primary.btn-lg {
  padding: 1rem;
}

.btn-outline-primary {
  background-color: #e5f8ff;
  color: #00aeef;
  border-color: transparent;
  font-size: 1rem;
  font-weight: 600;
}

.btn-outline-primary:hover, .btn-outline-primary:active, .btn-outline-primary:focus, .btn-outline-primary:not(:disabled):not(.disabled).active {
  background-color: #00aeef;
  color: #fff;
  border-color: transparent;
}

.btn-outline-primary:hover path, .btn-outline-primary:active path, .btn-outline-primary:focus path, .btn-outline-primary:not(:disabled):not(.disabled).active path {
  fill: #fff;
}

.btn-group-toggle .btn-outline-primary {
  background-color: transparent;
  border: 2px solid #00aeef;
  border-left-width: 0;
  color: #00aeef;
}

.btn-group-toggle .btn-outline-primary path {
  fill: #00aeef;
}

.btn-group-toggle .btn-outline-primary:first-child {
  border-left-width: 2px;
}

.btn-group-toggle .btn-outline-primary.focus {
  box-shadow: none;
}

@media (min-width: 768px) {
  .md-br {
    width: 100%;
  }
}

.vs {
  width: 1px;
  height: auto;
  background-color: rgba(28, 39, 58, 0.1);
}

@media (hover: none) {
  .d-touch-none {
    display: none;
  }
}

.d-touch-inline-block {
  display: none;
}

@media (hover: none) {
  .d-touch-inline-block {
    display: inline-block;
  }
}

.carousel-control-prev,
.carousel-control-next {
  width: 10px;
  opacity: 1;
}

@media (min-width: 576px) {
  .carousel-control-prev,
  .carousel-control-next {
    width: 40px;
  }
}

.carousel-control-prev:hover,
.carousel-control-next:hover {
  opacity: .5;
}

@media (hover: none) {
  .carousel-control-prev:hover,
  .carousel-control-next:hover {
    opacity: 1;
  }
}

.carousel-control-prev svg,
.carousel-control-next svg {
  width: 10px;
  height: 30px;
}

.carousel-control-prev svg path,
.carousel-control-next svg path {
  stroke-width: 8;
}

@media (min-width: 576px) {
  .carousel-control-prev svg,
  .carousel-control-next svg {
    width: 40px;
    height: 80px;
  }
  .carousel-control-prev svg path,
  .carousel-control-next svg path {
    stroke-width: 2;
  }
}

.carousel-control-prev {
  left: -35px;
}

@media (min-width: 576px) {
  .carousel-control-prev {
    left: -50px;
  }
}

.carousel-control-next {
  right: -35px;
}

@media (min-width: 576px) {
  .carousel-control-next {
    right: -50px;
  }
}

.carousel-indicators {
  bottom: -4rem;
}

.carousel-indicators li {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 1px solid #00aeef;
  background: transparent;
}

.carousel-indicators li.active {
  border-color: transparent;
  background: #00aeef;
}

.carousel-indicators li::before {
  display: none;
}

.carousel-indicators li::after {
  display: none;
}

.clients-list img {
  width: auto;
  height: auto;
}

.clients-list svg, .clients-list img {
  margin: 0 auto;
  display: block;
  max-width: 80px;
  max-height: 45px;
}

@media (min-width: 768px) {
  .clients-list svg, .clients-list img {
    max-width: 90px;
    max-height: 52px;
  }
}

@media (min-width: 992px) {
  .clients-list svg, .clients-list img {
    max-width: 100px;
    max-height: 62px;
  }
}

@media (min-width: 992px) {
  .clients-list svg, .clients-list img {
    max-width: 120px;
    max-height: 80px;
  }
}

ul.custom-bullets {
  list-style: none;
  padding: 0;
}

ul.custom-bullets li {
  position: relative;
  padding-left: 1rem;
  margin-top: 1rem;
}

ul.custom-bullets li::before {
  position: absolute;
  top: 0;
  left: 0;
  content: "●";
  color: #00aeef;
  font-weight: bold;
  margin-right: 1rem;
}

@media (min-width: 576px) {
  .lines-bg .bg1, .lines-bg .bg2, .lines-bg .bg3 {
    position: fixed;
    z-index: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .lines-bg .bg1::before, .lines-bg .bg1::after, .lines-bg .bg2::before, .lines-bg .bg2::after, .lines-bg .bg3::before, .lines-bg .bg3::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 1px;
    background: #f5f5f5;
  }
  .lines-bg .bg1::before {
    left: 28%;
  }
  .lines-bg .bg1::after {
    left: 72%;
  }
  .lines-bg .bg2::before {
    left: 39%;
  }
  .lines-bg .bg2::after {
    left: 61%;
  }
  .lines-bg .bg3::before {
    left: 50%;
  }
  .lines-bg .bg3::after {
    display: none;
  }
}

.page-header {
  position: relative;
  z-index: 9999;
  font-size: 14px;
  font-weight: 600;
}

.page-header .logo .st1 {
  fill: #00aeef;
}

.page-header .logo .st2 {
  fill: #1c273a;
}

.page-header .nav-link {
  padding-left: 1rem;
  padding-right: 1rem;
}

.page-header .link-with-arrow {
  display: flex;
  align-items: center;
  margin: 0;
}

.page-header .link-with-arrow svg {
  display: inline-block;
  width: 40px !important;
  margin: 0 0 0 8px;
}

@media (min-width: 768px) {
  .page-header .link-with-arrow svg {
    display: none;
  }
}

@media (min-width: 1200px) {
  .page-header .link-with-arrow svg {
    display: inline-block;
  }
}

.page-header .link-with-arrow path {
  stroke: #888;
}

.page-header .link-with-arrow:hover path, .page-header .link-with-arrow:active path {
  stroke: #1c273a;
  transform: none !important;
}

.page-header .navbar-nav.contacts {
  font-weight: normal;
}

.page-header .navbar-nav.contacts a {
  color: #00aeef;
}

.page-header .navbar-nav.contacts a:hover, .page-header .navbar-nav.contacts a:active {
  color: #00aeef;
  opacity: .75;
}

.meta-nav {
  height: 40px;
  padding: 0;
  background: #e6e6e6;
  color: #888;
}

.meta-nav a {
  color: #888;
}

.meta-nav a:hover, .meta-nav a:active {
  color: #1c273a;
}

.meta-nav .active a {
  color: #1c273a;
}

.meta-nav .vs {
  height: 24px;
  margin: 0 12px;
}

.main-nav {
  padding: 0;
  box-shadow: 8px 8px 32px 0 rgba(0, 0, 0, 0.1);
  transition: height 0.3s;
}

.main-nav.hidden {
  display: block;
  overflow: hidden;
  height: 0;
  transition: none;
}

.main-nav.hidden .container {
  overflow: hidden;
  height: 0;
}

.main-nav .main-menu {
  font-weight: 600;
}

.main-nav .main-menu a {
  opacity: .75;
}

@media (min-width: 768px) {
  .main-nav .main-menu a {
    opacity: 1;
  }
}

.main-nav .main-menu .primary-link a {
  opacity: 1;
}

.main-nav .contacts {
  border-left: 0 none;
}

.main-nav .contacts li {
  margin-right: 24px;
}

.main-nav .contacts .phone {
  width: 24px;
  height: 24px;
  background: url(/i/icons/phone.svg) no-repeat 0 0/24px 24px;
  font-size: 0;
}

.main-nav .contacts .email {
  width: 24px;
  height: 24px;
  background: url(/i/icons/email.svg) no-repeat 0 0/24px 24px;
  font-size: 0;
}

@media (min-width: 576px) {
  .main-nav .contacts {
    border-left: 1px solid rgba(28, 39, 58, 0.1);
  }
  .main-nav .contacts li {
    margin-right: 0;
  }
  .main-nav .contacts .phone, .main-nav .contacts .email {
    width: auto;
    height: auto;
    background: transparent;
    font-size: inherit;
  }
}

.main-nav .navbar-brand {
  display: flex;
  padding: .5rem;
}

@media (min-width: 768px) {
  .main-nav .navbar-brand {
    padding: 1rem 0;
  }
}

.main-nav .primary-link {
  font-size: 18px;
  padding: .3rem 0;
}

@media (min-width: 768px) {
  .main-nav .primary-link {
    font-size: 14px;
    padding: 0;
  }
}

.main-nav.navbar-light {
  background: #fff;
}

.main-nav.navbar-light .main-menu a {
  color: #1c273a;
}

.main-nav.navbar-light .main-menu a:hover, .main-nav.navbar-light .main-menu a:active {
  color: #00aeef;
}

.main-nav.navbar-light .main-menu .active a {
  color: #00aeef;
}

.main-nav.navbar-light .link-with-arrow path {
  stroke: #1c273a;
}

.main-nav.navbar-light .link-with-arrow:hover path, .main-nav.navbar-light .link-with-arrow:active path {
  stroke: #00aeef;
}

.main-nav.navbar-light .contacts {
  border-color: rgba(28, 39, 58, 0.1);
}

.main-nav.navbar-dark {
  background-color: #1c273a;
}

.main-nav.navbar-dark .main-menu a {
  color: #fff;
}

.main-nav.navbar-dark .main-menu a:hover, .main-nav.navbar-dark .main-menu a:active {
  color: #00aeef;
}

.main-nav.navbar-dark .main-menu .active a {
  color: #00aeef;
}

.main-nav.navbar-dark .link-with-arrow path {
  stroke: #fff;
}

.main-nav.navbar-dark .link-with-arrow:hover path, .main-nav.navbar-dark .link-with-arrow:active path {
  stroke: #00aeef;
}

.main-nav.navbar-dark .contacts {
  border-color: rgba(255, 255, 255, 0.1);
}

@media (min-width: 768px) {
  .navbar-expand-md .hamburger {
    display: none;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg .hamburger {
    display: none;
  }
}

.page-footer {
  position: relative;
  background: #1c273a;
  color: #888;
  font-size: 14px;
  font-weight: 600;
}

.page-footer .nav-link {
  padding-left: 1rem;
  padding-right: 1rem;
}

.page-footer .social-btn-list path {
  fill: #fff;
}

.page-footer .social-btn-list a {
  display: block;
  margin: 0 4px;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  background: transparent;
  text-align: center;
  transition: background 0.5s;
}

@media (min-width: 576px) {
  .page-footer .social-btn-list a {
    margin: 0 8px;
  }
}

.page-footer .social-btn-list a svg {
  margin-top: 4px;
}

.page-footer .social-btn-list a:hover, .page-footer .social-btn-list a:active, .page-footer .social-btn-list a:focus {
  background: #00aeef;
}

.footer-nav {
  padding: 1rem 0;
}

.footer-nav.navbar-dark .navbar-nav .nav-link {
  color: #fff;
}

.footer-nav.navbar-dark .navbar-nav .nav-link:hover, .footer-nav.navbar-dark .navbar-nav .nav-link:active, .footer-nav.navbar-dark .navbar-nav .nav-link:focus {
  color: #00aeef;
}

.achievements-carousel .col {
  text-align: center;
}

.achievements-carousel b {
  display: block;
  font-weight: 800;
  font-size: 90px;
}

@media (min-width: 1200px) {
  .achievements-carousel b {
    font-size: 120px;
  }
}

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  margin: 0 16px 0 0;
  display: flex;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  overflow: visible;
}

.hamburger:hover {
  opacity: 0.6;
}

.hamburger:focus {
  outline: none;
}

.hamburger-box {
  width: 24px;
  height: 16px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -1px;
}

.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  width: 24px;
  height: 2px;
  background-color: #00aeef;
  border-radius: 2px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

.hamburger-inner::before, .hamburger-inner::after {
  content: "";
  display: block;
}

.hamburger-inner::before {
  top: -7px;
}

.hamburger-inner::after {
  bottom: -7px;
}

.hamburger--elastic .hamburger-inner {
  top: 1px;
  transition-duration: 0.275s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.hamburger--elastic .hamburger-inner::before {
  top: 7px;
  transition: opacity 0.125s 0.275s ease;
}

.hamburger--elastic .hamburger-inner::after {
  top: 14px;
  transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.hamburger--elastic:not(.collapsed) .hamburger-inner {
  transform: translate3d(0, 7px, 0) rotate(135deg);
  transition-delay: 0.075s;
}

.hamburger--elastic:not(.collapsed) .hamburger-inner::before {
  transition-delay: 0s;
  opacity: 0;
}

.hamburger--elastic:not(.collapsed) .hamburger-inner::after {
  transform: translate3d(0, -14px, 0) rotate(-270deg);
  transition-delay: 0.075s;
}

.link-refresh {
  position: relative;
  padding-left: 28px;
  font-size: 14px;
  font-weight: 600;
}

.link-refresh:hover, .link-refresh:active, .link-refresh:focus {
  text-decoration: none;
}

.link-refresh:before {
  display: block;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 16px;
  height: 16px;
  background: url(/i/icons/reload.svg) no-repeat 0 center/16px 16px;
  transition: transform 0.3s;
}

.link-refresh:hover:before {
  transform: rotate(180deg);
}

.link-with-arrow {
  display: block;
  max-width: 250px;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  margin-top: 1rem;
}

@media (min-width: 576px) {
  .link-with-arrow {
    margin-top: 48px;
  }
}

.link-with-arrow em {
  color: #00aeef;
  font-weight: 600;
  font-style: normal;
}

.link-with-arrow path {
  stroke: #00aeef;
}

.link-with-arrow svg {
  display: none;
  width: 72px;
  height: 16px;
  margin-bottom: 1rem;
  transition: width 0.5s;
}

@media (min-width: 576px) {
  .link-with-arrow svg {
    display: block;
  }
}

.link-with-arrow svg .shaft, .link-with-arrow svg .end {
  transform-origin: center center;
  transition: transform 0.5s;
}

.link-with-arrow:hover {
  color: #00aeef;
}

.link-with-arrow:hover svg {
  width: 108px;
}

.link-with-arrow:hover svg .shaft {
  transform: scaleX(1.5);
}

.link-with-arrow:hover svg .end {
  transform: translateX(18px);
}

.speechbubble {
  position: relative;
  background: #00aeef;
  box-shadow: 16px 16px 32px 0 rgba(0, 174, 239, 0.4);
  color: #fff;
  letter-spacing: .2px;
  transition: none;
  max-width: none;
  border-radius: 20px;
  padding: 16px 20px;
  font-weight: 300;
}

@media (min-width: 576px) {
  .speechbubble {
    max-width: 80%;
    padding: 18px 24px;
  }
}

@media (min-width: 992px) {
  .speechbubble {
    max-width: 50%;
  }
}

@media (min-width: 1200px) {
  .speechbubble {
    border-radius: 40px;
    padding: 28px 32px;
  }
}

.speechbubble.js-speechbubble {
  opacity: 0;
  transform: translate(-10px, -10px);
}

.speechbubble.js-animated, .speechbubble.shown {
  transition: all 0.8s;
  opacity: 1;
  transform: translate(0, 0);
}

.speechbubble p:last-child, .speechbubble p:last-of-type {
  margin-bottom: 0;
}

.speechbubble a {
  color: inherit;
  text-decoration: underline;
}

.speechbubble-tl {
  border-top-left-radius: 0;
}

.speechbubble-tr {
  border-top-right-radius: 0;
}

.speechbubble-dl {
  border-bottom-left-radius: 0;
}

.speechbubble-dr {
  border-bottom-right-radius: 0;
}

.speechbubble .likes-container {
  position: absolute;
  right: 0;
  bottom: -2.5rem;
  display: flex;
  cursor: pointer;
}

.speechbubble .likes-container .count {
  margin: 0 0 0 8px;
  color: #00aeef;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
}

.speechbubble .likes-container .heart .contour {
  fill: #00aeef;
}

.speechbubble .likes-container .heart .fill {
  fill: none;
}

.speechbubble .likes-container:hover .heart .contour {
  fill: none;
}

.speechbubble .likes-container:hover .heart .fill {
  fill: #00aeef;
}

.speechbubble .likes-container.active .heart {
  animation: 0.2s ease-in-out 0s 1 normal none running likeAnimation;
}

.speechbubble .likes-container.active .heart .contour {
  fill: none;
}

.speechbubble .likes-container.active .heart .fill {
  fill: #ff4365;
}

.speechbubble[data-likes] {
  margin-bottom: 3rem;
}

@keyframes likeAnimation {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.subscribe-block {
  margin: 4rem 0 0;
  padding: 4rem 0;
  background-color: #00aeef;
  color: #1c273a;
  text-align: center;
}

.subscribe-block h2 {
  max-width: 502px;
  margin: 0 auto 2rem;
  font-size: 20px;
}

@media (min-width: 768px) {
  .subscribe-block h2 {
    font-size: 24px;
  }
}

.subscribe-block p {
  max-width: 502px;
  margin: 1rem auto 0;
}

.subscribe-block a {
  color: #000;
  font-weight: 500;
}

.subscribe-block input[type="text"], .subscribe-block input[type="email"] {
  max-width: 562px;
  width: 100%;
  background: transparent;
  border: 2px solid #00bdf8;
  border-radius: 6px;
  padding: 22px 55px 22px 22px;
  color: #fff;
}

.subscribe-block input[type="text"]:placeholder, .subscribe-block input[type="email"]:placeholder {
  color: #00bdf8;
}

.subscribe-block button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  border: 0 none;
  padding: 22px 12px;
  margin: 0 0 0 -55px;
  line-height: inherit;
  cursor: pointer;
}

.about-quote-block .container {
  overflow: hidden;
}

@media (min-width: 768px) {
  .about-quote-block .container {
    overflow: visible;
  }
}

.about-quote-block.vacancies .large-quote {
  margin-top: 0;
}

.large-quote {
  position: relative;
  margin-top: 4rem;
  padding-bottom: 0;
}

@media (min-width: 768px) {
  .large-quote {
    margin-top: 8rem;
    padding-bottom: 4rem;
  }
}

.large-quote .speechbubble {
  position: relative;
  z-index: 1;
  max-width: none;
}

@media (min-width: 768px) {
  .large-quote .speechbubble {
    max-width: 60%;
    margin-right: auto;
    margin-left: 0;
  }
}

@media (min-width: 992px) {
  .large-quote .speechbubble {
    margin-right: 0;
    margin-left: auto;
  }
}

.large-quote .author {
  position: relative;
  z-index: 1;
  margin-top: 6rem;
  margin: 6rem 0 0;
  font-size: 14px;
}

.large-quote .author span {
  display: block;
}

@media (min-width: 768px) {
  .large-quote .author {
    max-width: 60%;
    margin: 2rem auto 0 0;
  }
}

@media (min-width: 992px) {
  .large-quote .author {
    margin: 2rem 0 0 auto;
  }
}

.large-quote .author-photo {
  position: relative;
  z-index: -1;
  margin: -8rem -2rem 0 0;
  text-align: right;
}

.large-quote .author-photo img {
  width: auto;
  height: auto;
  max-height: 200px;
}

@media (min-width: 768px) {
  .large-quote .author-photo {
    position: absolute;
    max-width: 50%;
    margin: 0;
    left: auto;
    right: -4rem;
    bottom: 0;
  }
  .large-quote .author-photo img {
    max-width: 100%;
    max-height: none;
  }
}

@media (min-width: 992px) {
  .large-quote .author-photo {
    margin-left: 0;
    left: -6rem;
    right: auto;
  }
}

.large-quote .name {
  font-weight: 600;
}

@media (min-width: 768px) {
  .large-quote.large-quote-reversed .speechbubble {
    margin-right: auto;
    margin-left: 0;
  }
}

.large-quote.large-quote-reversed .author {
  margin-left: 0;
  margin-right: auto;
}

@media (min-width: 768px) {
  .large-quote.large-quote-reversed .author {
    text-align: right;
  }
}

@media (min-width: 768px) {
  .large-quote.large-quote-reversed .author-photo {
    bottom: -3rem;
  }
}

@media (min-width: 992px) {
  .large-quote.large-quote-reversed .author-photo {
    left: auto;
    right: -8rem;
  }
}

.about-achievements-block {
  padding: 2rem 0;
  background-color: #1c273a;
  color: #fff;
}

@media (min-width: 576px) {
  .about-achievements-block {
    padding: 4rem 0;
  }
}

.about-achievements-block li {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.about-achievements-block b {
  display: block;
  margin-bottom: .5rem;
  font-size: 24px;
  font-weight: bold;
}

@media (min-width: 1200px) {
  .about-achievements-block b {
    font-size: 40px;
  }
}

.about-achievements-block .dz-logo {
  max-width: 192px;
}

.about-achievements-block .carousel-inner {
  min-height: 440px;
}

.about-process-block {
  padding: 4rem 0 2rem;
  background-color: #f5f5f5;
}

.about-process-block li {
  margin-bottom: 2rem;
}

.about-process-block p:last-child {
  margin-bottom: 0;
}

.call-to-action {
  padding: 4rem 0;
}

.call-to-action p {
  font-weight: 600;
}

.call-to-action h2 {
  font-size: 20px;
}

@media (min-width: 576px) {
  .call-to-action h2 {
    font-size: 24px;
  }
}

@media (min-width: 768px) {
  .call-to-action h2 {
    font-size: 24px;
  }
}

@media (min-width: 992px) {
  .call-to-action h2 {
    font-size: 24px;
  }
}

@media (min-width: 1200px) {
  .call-to-action h2 {
    font-size: 40px;
  }
}

.activities-block {
  padding-top: 4rem;
  background: url(/i/bg/activities.svg) no-repeat 65% 50%/contain;
}

.activities-block h2 {
  font-size: 20px;
  max-width: 624px;
}

@media (min-width: 576px) {
  .activities-block h2 {
    font-size: 24px;
  }
}

@media (min-width: 768px) {
  .activities-block h2 {
    font-size: 40px;
  }
}

@media (min-width: 992px) {
  .activities-block h2 {
    font-size: 40px;
  }
}

@media (min-width: 1200px) {
  .activities-block h2 {
    font-size: 48px;
  }
}

.activities-block .carousel {
  margin: 2rem;
}

@media (min-width: 768px) {
  .activities-block .carousel {
    margin: 4rem 5rem;
  }
}

.activities-block .carousel-inner {
  min-height: 210px;
}

@media (min-width: 768px) {
  .activities-block .carousel-inner {
    min-height: 300px;
  }
}

.vacancies-link {
  position: relative;
  z-index: 2;
  margin-bottom: 2rem;
}

@media (min-width: 768px) {
  .vacancies-link {
    margin: -9rem 0 2rem;
  }
}

.about-page .vacancies-photos-block {
  padding-top: 1px;
  background-color: #f5f5f5;
}

.about-timeline {
  margin: 4rem 0 4rem 2rem;
}

@media (min-width: 576px) {
  .about-timeline {
    margin: 4rem 0 4rem 6rem;
  }
}

@media (min-width: 992px) {
  .about-timeline {
    margin: 4rem 0 12rem;
  }
}

.about-timeline dl {
  position: relative;
  display: flex;
  padding-bottom: 4rem;
  flex-direction: column;
}

@media (min-width: 992px) {
  .about-timeline dl {
    padding-bottom: 0;
    flex-direction: row;
  }
}

.about-timeline dl::after {
  content: "";
  display: block;
  position: absolute;
  opacity: .2;
  top: 22px;
  right: auto;
  bottom: 0;
  left: 0;
  width: 1px;
  height: auto;
  background-image: linear-gradient(to bottom, #00aeef, #00aeef 86%, rgba(0, 174, 239, 0));
}

@media (min-width: 992px) {
  .about-timeline dl::after {
    top: auto;
    right: 0;
    bottom: -30px;
    left: 39px;
    width: auto;
    height: 1px;
    background-image: linear-gradient(to right, #00aeef, #00aeef 86%, rgba(0, 174, 239, 0));
  }
}

.about-timeline dl::before {
  content: "";
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  background: url(/i/icons/flag.svg) no-repeat 0 0/16px 16px;
  top: 8px;
  bottom: auto;
  left: 0;
}

@media (min-width: 992px) {
  .about-timeline dl::before {
    top: auto;
    bottom: -30px;
    left: 39px;
  }
}

.about-timeline div {
  position: relative;
  margin: .5rem 0 .5rem 3rem;
}

@media (min-width: 992px) {
  .about-timeline div {
    margin: 0 .8rem;
  }
}

.about-timeline dt {
  position: relative;
  display: flex;
  color: #66d7fb;
}

@media (min-width: 992px) {
  .about-timeline dt {
    align-items: flex-end;
    height: 70px;
    line-height: 70px;
  }
}

@media (min-width: 1200px) {
  .about-timeline dt {
    height: 120px;
    line-height: 120px;
  }
}

.about-timeline dt::after {
  content: "";
  display: block;
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #ffffff;
  border: solid 1px #00aeef;
  top: 50%;
  bottom: auto;
  left: -3rem;
  transform: translate(-50%, -50%);
}

@media (min-width: 992px) {
  .about-timeline dt::after {
    top: auto;
    bottom: -30px;
    left: 50%;
    transform: translate(-50%, 50%);
  }
}

.about-timeline dt span {
  transition: all 0.4s;
  line-height: normal;
  font-size: 20px;
}

.about-timeline div:first-child dt::after {
  background-color: #00aeef;
}

.about-timeline div:nth-last-child(3) dd,
.about-timeline div:nth-last-child(2) dd,
.about-timeline div:nth-last-child(1) dd {
  right: 0;
}

.about-timeline .active dt {
  color: #00aeef;
}

@media (min-width: 1200px) {
  .about-timeline .active dt {
    font-size: 110px;
  }
}

.about-timeline .active dt::after {
  width: 16px;
  height: 16px;
  border: 0 none;
  background-color: #00aeef;
}

.about-timeline .active dt::before {
  content: "";
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #00aeef;
  opacity: .1;
  bottom: 50%;
  left: -3rem;
  transform: translate(-50%, 50%);
}

@media (min-width: 992px) {
  .about-timeline .active dt::before {
    bottom: -30px;
    left: 50%;
    transform: translate(-50%, 50%);
  }
}

.about-timeline .active dt span {
  font-size: 80px;
}

@media (min-width: 992px) {
  .about-timeline .active dt span {
    line-height: 70px;
  }
}

@media (min-width: 1200px) {
  .about-timeline .active dt span {
    line-height: 90px;
    font-size: 110px;
  }
}

@media (min-width: 992px) {
  .about-timeline dd {
    position: absolute;
    bottom: -100px;
    white-space: nowrap;
  }
}

.contacts-page .page-title {
  position: relative;
  padding-top: 2rem;
}

.contacts-page .page-title strong {
  font-weight: 600;
}

.contacts-page .page-title h1 {
  margin: 1rem 0 2rem;
}

@media (min-width: 576px) {
  .contacts-page .page-title h1 {
    margin-bottom: 8rem;
  }
}

.contacts-page .page-title .speechbubble {
  max-width: 100%;
  margin-bottom: 2rem;
}

@media (min-width: 768px) {
  .contacts-page .page-title .speechbubble {
    margin-bottom: 0;
    max-width: 424px;
  }
}

@media (min-width: 576px) {
  .contacts-page .page-title .speechbubble {
    position: absolute;
    bottom: -11rem;
    right: 0;
    z-index: 2;
  }
}

.map .map-container {
  width: 100%;
  height: 400px;
}

.map-addresses li {
  margin-top: 1rem;
}

@media (min-width: 576px) {
  .map-addresses li {
    margin-top: 4rem;
  }
}

.map-addresses address {
  position: relative;
  margin-left: 40px;
}

@media (min-width: 576px) {
  .map-addresses address {
    margin-left: 8px;
  }
}

.map-addresses address::before {
  display: block;
  content: "";
  position: absolute;
  left: -32px;
  top: 0;
  width: 24px;
  height: 24px;
  background: url(/i/icons/map-point.svg) no-repeat 0 0/24px 24px;
}

.map-addresses address.active::before {
  background-image: url(/i/icons/map-point-active.svg);
}

.map-addresses p.address-title {
  margin: 0 0 .5rem;
  font-weight: 600;
  color: #00aeef;
  cursor: pointer;
}

.map-addresses p {
  margin: 0;
}

.academy-intro .container {
  position: relative;
}

.academy-intro h1 {
  max-width: none;
}

@media (min-width: 576px) {
  .academy-intro h1 {
    max-width: 50%;
  }
}

@media (min-width: 576px) {
  .academy-intro .speechbubble {
    position: absolute;
    top: -2rem;
    right: 0;
    max-width: 50%;
  }
}

.academy-about {
  margin-top: 2rem;
  padding-bottom: 4rem;
}

@media (min-width: 576px) {
  .academy-about {
    margin-top: 8rem;
  }
}

.academy-about article {
  position: relative;
  margin: 0;
  padding-top: 48px;
}

@media (min-width: 576px) {
  .academy-about article {
    margin: 2rem 0;
  }
}

.academy-about article::before {
  display: block;
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  width: 32px;
  height: 32px;
  background: transparent no-repeat 0 0 / 32px 32px;
}

@media (min-width: 576px) {
  .academy-about article::before {
    left: 12px;
    transform: none;
  }
}

.academy-about .teachers::before {
  background-image: url(/i/icons/teachers.svg);
}

.academy-about .application::before {
  background-image: url(/i/icons/application.svg);
}

.academy-about .qualification::before {
  background-image: url(/i/icons/qualification.svg);
}

.academy-about .materials::before {
  background-image: url(/i/icons/materials.svg);
}

.academy-about .certificate::before {
  background-image: url(/i/icons/certificate.svg);
}

.academy-about .comfort::before {
  background-image: url(/i/icons/comfort.svg);
}

.academy-about .chat::before {
  background-image: url(/i/icons/chat.svg);
}

.academy-about .jobs::before {
  background-image: url(/i/icons/jobs.svg);
}

.academy-about .carousel {
  margin: 3rem 3rem 0;
}

.academy-about .carousel-inner {
  min-height: 140px;
}

.academy-info {
  padding: 2rem 0 3rem;
  background: #f5f5f5;
}

@media (min-width: 576px) {
  .academy-info {
    padding: 5rem 0;
  }
}

.academy-info .btn-group {
  margin: 0 0 3rem;
}

.academy-info .btn-group .btn {
  padding: 6px 24px;
  height: 46px;
  border-radius: 8px;
}

.academy-info .btn-group .btn:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.academy-info .btn-group .btn:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

@media (min-width: 992px) {
  .academy-info .event-date {
    margin-top: 8rem;
  }
}

.academy-info .event-date b {
  display: block;
  font-size: 20px;
  font-weight: bold;
}

@media (min-width: 576px) {
  .academy-info .event-date b {
    font-size: 24px;
  }
}

@media (min-width: 768px) {
  .academy-info .event-date b {
    font-size: 24px;
  }
}

@media (min-width: 992px) {
  .academy-info .event-date b {
    font-size: 40px;
  }
}

@media (min-width: 1200px) {
  .academy-info .event-date b {
    font-size: 48px;
  }
}

.academy-info .event-description {
  margin: 2rem 0 0;
}

.course-providers a {
  display: block;
  text-align: center;
}

.course-providers img {
  max-width: 100%;
  max-height: 50px;
  width: auto;
  height: auto;
}

.education-numbers {
  padding: 4rem 0 6rem;
  background: #1c273a;
  color: #fff;
}

.education-numbers h2 {
  margin: 0 0 4rem;
}

.education-numbers b {
  display: block;
  font-size: 30px;
  font-weight: bold;
}

@media (min-width: 992px) {
  .education-numbers b {
    font-size: 40px;
  }
}

.digest h1 {
  margin-bottom: 2rem;
}

@media (min-width: 992px) {
  .digest h1 {
    max-width: 50%;
  }
}

.digest p {
  margin-bottom: 3rem;
}

.digest .container {
  position: relative;
}

.digest .speechbubble {
  margin-top: 2rem;
}

@media (min-width: 992px) {
  .digest .speechbubble {
    margin-top: 0;
    position: absolute;
    top: -8rem;
    right: 0;
  }
}

.digest-authors {
  font-size: 14px;
}

.digest-authors li {
  margin-bottom: 2rem;
}

.digest-authors img {
  margin-right: 1rem;
  border-radius: 50%;
}

.digest-authors .name {
  font-weight: 600;
}

.error-block {
  position: relative;
  padding: 2rem 0 6rem 0;
}

@media (min-width: 768px) {
  .error-block {
    padding: 6rem 0 6rem 0;
  }
}

.error-block .speechbubble {
  max-width: 312px;
  margin: 0 auto 3rem;
}

@media (min-width: 576px) {
  .error-block .speechbubble {
    transform: translateX(50%);
  }
}

.error-block h1 {
  max-width: none;
  margin: 1rem auto;
  text-align: center;
  font-size: 80px;
}

@media (min-width: 1200px) {
  .error-block h1 {
    font-size: 120px;
  }
}

.error-block h2 {
  max-width: none;
  margin: 1rem auto;
  text-align: center;
  font-size: 14px;
  font-weight: normal;
}

@media (min-width: 768px) {
  .error-block h2 {
    font-size: 16px;
  }
}

@media (min-width: 1200px) {
  .error-block h2 {
    font-size: 18px;
  }
}

.body-events {
  background-color: #1c273a;
  color: #fff;
}

.body-events .logo .st1 {
  fill: #00aeef;
}

.body-events .logo .st2 {
  fill: #ffffff;
}

.body-events .meta-nav {
  background-color: #323c4d;
}

.meetups h1 {
  margin-bottom: 2rem;
}

.big-event {
  margin: 2rem 0 4rem;
}

@media (min-width: 992px) {
  .big-event img {
    max-width: 608px;
  }
}

@media (min-width: 1200px) {
  .big-event img {
    max-width: 832px;
  }
}

.big-event .event-date {
  margin: 1rem 0;
}

.big-event .big-number {
  line-height: 1.2;
  margin: 2rem 0;
}

.big-event .big-number b {
  display: block;
  font-size: 24px;
  font-weight: bold;
}

@media (min-width: 992px) {
  .big-event .big-number b {
    font-size: 40px;
  }
}

@media (min-width: 1200px) {
  .big-event .big-number b {
    font-size: 56px;
  }
}

.big-event .event-description {
  margin: 2rem 0;
  max-width: 624px;
}

.big-event .event-logos {
  padding: 0 40px;
}

.big-event .event-logos .clients-list path {
  fill: #fff;
}

.big-event .event-logos .carousel-inner .row {
  height: 90px;
}

.small-event {
  margin-bottom: 4rem;
  background: #162f48;
}

.small-event .event-meta {
  padding: 30px;
}

.small-event .event-description {
  font-size: 20px;
  font-weight: bold;
  margin-top: 1rem;
}

@media (min-width: 768px) {
  .small-event .event-description {
    font-size: 24px;
  }
}

.faq {
  background: #e8f9ff;
  padding: 2rem 0 4rem;
}

.faq .faq-item {
  position: relative;
  margin-bottom: .5rem;
  padding: 1rem 2.2rem .5rem;
  border: 1px solid transparent;
  border-radius: 16px;
  cursor: pointer;
}

@media (min-width: 768px) {
  .faq .faq-item {
    margin-bottom: 2rem;
  }
}

@media (min-width: 768px) {
  .faq .faq-item {
    padding: 2rem 8rem .5rem;
  }
}

.faq .faq-item::before {
  display: block;
  content: "";
  position: absolute;
  left: .2rem;
  top: .8rem;
  width: 24px;
  height: 24px;
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij4NCiAgPHBhdGggZmlsbD0ibm9uZSIgc3Ryb2tlPSIjMDBhZWVmIiBzdHJva2Utd2lkdGg9IjIiIGQ9Ik03IDExbDUgNCA1LTQiLz4NCjwvc3ZnPg0K") no-repeat 0 0/24px 24px;
  transition: all 0.3s;
}

@media (min-width: 768px) {
  .faq .faq-item::before {
    left: 4rem;
    top: 2rem;
  }
}

.faq .faq-item.show {
  background: #ffffff;
  border-color: #f5f5f5;
  box-shadow: 8px 8px 32px 0 rgba(0, 0, 0, 0.1);
}

.faq .faq-item.show::before {
  transform: rotate(180deg);
}

.faq .faq-question {
  max-width: none;
  margin-bottom: 1rem;
  font-size: 1rem;
  font-weight: 600;
}

@media (min-width: 768px) {
  .faq .faq-question {
    font-size: 24px;
    font-weight: 800;
  }
}

@media (min-width: 1200px) {
  .main-page h1 {
    font-size: 72px;
  }
}

.main-page h2 {
  margin-bottom: 1rem;
}

@media (min-width: 1200px) {
  .main-page h2 {
    font-size: 48px;
  }
}

@media (min-width: 576px) {
  .main-page h2 {
    margin-bottom: 3rem;
  }
}

@media (min-width: 992px) {
  .main-page .speechbubble {
    width: 416px;
  }
}

@media (min-width: 576px) {
  .main-page .speechbubble {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.main-section {
  position: relative;
}

.main-section .container {
  position: relative;
}

.main-title-block {
  padding: 2rem 0;
}

@media (min-width: 576px) {
  .main-title-block {
    padding: 12rem 0 4rem;
  }
}

@media (min-width: 992px) {
  .main-title-block {
    padding: 10rem 0 4rem;
  }
}

.main-title-block h1 {
  margin: 0;
}

@media (min-width: 992px) {
  .main-title-block h1 {
    max-width: 70%;
  }
}

@media (min-width: 1200px) {
  .main-title-block h1 {
    max-width: 1040px;
  }
}

@media (min-width: 576px) {
  .main-title-block .speechbubble {
    top: -11rem;
  }
}

@media (min-width: 992px) {
  .main-title-block .speechbubble {
    top: -8rem;
  }
}

.verified-by {
  text-align: center;
  margin-top: 2rem;
}

@media (min-width: 576px) {
  .verified-by {
    margin-top: -3rem;
  }
}

@media (min-width: 992px) {
  .verified-by {
    margin-top: 0;
  }
}

@media (min-width: 1200px) {
  .verified-by {
    margin-top: 2rem;
  }
}

.verified-by p {
  float: left;
  writing-mode: sideways-lr;
  margin: 1rem 0 0 0;
}

@media (min-width: 576px) {
  .verified-by p {
    float: none;
    writing-mode: horizontal-tb;
    margin: 0 0 1rem 0;
  }
}

.verified-by img {
  max-width: 100px;
}

@media (min-width: 992px) {
  .verified-by img {
    max-width: 120px;
  }
}

@media (min-width: 1200px) {
  .verified-by img {
    max-width: 160px;
  }
}

.main-projects-block {
  padding: 2rem 0;
}

.main-projects-block .projects-list-wrapper {
  overflow: hidden;
  height: 176px;
}

@media (min-width: 576px) {
  .main-projects-block .projects-list-wrapper {
    height: 224px;
  }
}

@media (min-width: 992px) {
  .main-projects-block .projects-list-wrapper {
    height: 304px;
  }
}

@media (min-width: 1200px) {
  .main-projects-block .projects-list-wrapper {
    height: 416px;
  }
}

.main-projects-block .projects-list {
  overflow-y: hidden;
  overflow-x: scroll;
  white-space: nowrap;
}

.main-projects-block .projects-list-item {
  display: inline-block;
  white-space: normal;
  width: 176px;
  height: 176px;
}

@media (min-width: 576px) {
  .main-projects-block .projects-list-item {
    width: 224px;
    height: 224px;
  }
}

@media (min-width: 992px) {
  .main-projects-block .projects-list-item {
    width: 304px;
    height: 304px;
  }
}

@media (min-width: 1200px) {
  .main-projects-block .projects-list-item {
    width: 416px;
    height: 416px;
  }
}

.main-process-block {
  padding: 2rem 0 4rem;
}

@media (min-width: 576px) {
  .main-process-block h2 {
    max-width: 50%;
  }
}

@media (min-width: 768px) {
  .main-process-block h2 {
    width: 416px;
  }
}

.main-process-block .speechbubble {
  width: auto;
}

@media (min-width: 576px) {
  .main-process-block .speechbubble {
    width: 50%;
    top: -2rem;
  }
}

@media (min-width: 768px) {
  .main-process-block .speechbubble {
    width: 356px;
  }
}

@media (min-width: 992px) {
  .main-process-block .speechbubble {
    width: auto;
  }
}

.main-process-block .main-process-list li {
  margin: 1rem 0;
}

.main-process-block .main-process-list a {
  text-align: left;
  margin-bottom: 1rem;
  font-size: 20px;
  font-weight: bold;
  color: #00aeef;
}

@media (min-width: 768px) {
  .main-process-block .main-process-list a {
    font-size: 24px;
  }
}

.main-process-block .main-process-list a::before {
  left: 0;
  transform: translateX(8px);
}

.main-achievements-block {
  padding: 2rem 0;
  margin-bottom: 2rem;
  background: url(/i/bg/achievements.svg) no-repeat center center/contain;
}

@media (min-width: 576px) {
  .main-achievements-block {
    padding: 10rem 0 8rem;
  }
}

@media (min-width: 576px) {
  .main-achievements-block {
    margin-bottom: 4rem;
  }
}

.main-achievements-block .speechbubble {
  margin: -2rem 0 2rem;
}

@media (min-width: 576px) {
  .main-achievements-block .speechbubble {
    margin: 0;
    top: -10rem;
  }
}

.main-achievements-block .carousel {
  margin: 0 2rem 4rem;
}

@media (min-width: 768px) {
  .main-achievements-block .carousel {
    margin: 0 5rem;
  }
}

.main-achievements-block .carousel-inner {
  min-height: 250px;
}

@media (min-width: 768px) {
  .main-achievements-block .carousel-inner {
    min-height: 340px;
  }
}

.main-clients-block {
  padding: 6rem 0 2rem;
  background-color: #f5f5f5;
}

.main-clients-block .speechbubble {
  margin-top: -2rem;
  max-width: 100%;
  width: 624px;
}

@media (min-width: 576px) {
  .main-clients-block .speechbubble {
    top: -9rem;
    margin-top: 0;
  }
}

.main-clients-block .clients-list path {
  fill: #000;
}

.main-clients-block .testimonial-bubble {
  border-radius: 40px;
  border-bottom-left-radius: 0;
  border: solid 1px #f5f5f5;
  margin: 0;
  padding: 40px;
  background-color: #ffffff;
}

@media (min-width: 768px) {
  .main-clients-block .testimonial-bubble {
    height: 262px;
    margin: 32px;
    box-shadow: 8px 8px 32px 0 rgba(0, 0, 0, 0.1);
  }
}

.main-clients-block .testimonial-text {
  position: relative;
  max-height: 7.6rem;
  overflow: hidden;
  margin-bottom: 1rem;
}

.main-clients-block .testimonial-text:after {
  content: " ";
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 70%;
  line-height: 1.43;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), white 50%);
}

@media (min-width: 768px) {
  .main-clients-block .testimonial-text:after {
    line-height: 1.56;
  }
}

.main-clients-block .testimonial-author {
  font-size: 14px;
  text-align: center;
}

@media (min-width: 768px) {
  .main-clients-block .testimonial-author {
    max-width: 70%;
    margin: 0 32px;
    text-align: left;
  }
}

.main-clients-block .testimonial-author img {
  border-radius: 50%;
}

.main-clients-block .testimonial-author .author-image {
  display: block;
  margin: 0 0 1rem;
}

.main-clients-block .testimonial-author .name {
  font-weight: bold;
}

.main-clients-block .carousel {
  margin: 0;
}

@media (min-width: 768px) {
  .main-clients-block .carousel {
    margin: 0 5rem;
  }
}

.main-clients-block .carousel-control-prev {
  top: 50px;
  bottom: auto;
  left: 10%;
}

.main-clients-block .carousel-control-prev svg {
  width: 20px;
  height: 40px;
}

@media (min-width: 768px) {
  .main-clients-block .carousel-control-prev {
    top: 0;
    bottom: 0;
    left: -15%;
  }
  .main-clients-block .carousel-control-prev svg {
    width: auto;
    height: auto;
  }
}

.main-clients-block .carousel-control-next {
  top: 50px;
  bottom: auto;
  right: 10%;
}

.main-clients-block .carousel-control-next svg {
  width: 20px;
  height: 40px;
}

@media (min-width: 768px) {
  .main-clients-block .carousel-control-next {
    top: 0;
    bottom: 0;
    right: -15%;
  }
  .main-clients-block .carousel-control-next svg {
    width: auto;
    height: auto;
  }
}

.main-clients-block .link-with-arrow {
  margin: 1rem 0 4rem 0;
}

@media (min-width: 768px) {
  .main-clients-block .link-with-arrow {
    position: relative;
    margin: -3rem 0 7rem auto;
  }
}

.main-events-block {
  padding: 6rem 0;
  background-color: #1c273a;
  color: #fff;
}

.main-events-block .speechbubble {
  margin: -10rem 0 2rem;
}

@media (min-width: 576px) {
  .main-events-block .speechbubble {
    top: -10rem;
    margin: 0;
  }
}

.main-events-block .event-date {
  margin: 3rem 0 2rem;
}

.main-education-block {
  padding: 6rem 0;
}

.main-education-block .speechbubble {
  margin: -10rem 0 2rem;
}

@media (min-width: 576px) {
  .main-education-block .speechbubble {
    top: -10rem;
    margin: 0;
  }
}

.main-education-block .embed-responsive {
  max-width: 832px;
}

.main-education-block .course-providers {
  margin: 6rem auto 2rem;
}

.main-faq-block {
  padding: 6rem 0;
  background-color: #00aeef;
}

.main-faq-block h2 {
  max-width: none;
}

.main-faq-block a {
  color: #fff;
}

.main-faq-block a:hover, .main-faq-block a:active, .main-faq-block a:focus {
  color: #fff;
}

.main-faq-block path {
  stroke: #fff;
}

.main-faq-block .main-faq-list {
  margin-top: 2rem;
  max-width: 832px;
}

.portfolio-page h1 {
  margin-bottom: 2.5rem;
}

.portfolio-page .projects-list {
  margin: 4rem 0;
}

.projects-tags {
  line-height: 2.5;
}

.period-select-wrapper {
  position: relative;
}

.period-select-label {
  font-weight: bold;
  color: #00aeef;
  cursor: pointer;
  white-space: nowrap;
}

.period-select-label::after {
  content: "";
  display: inline-block;
  vertical-align: middle;
  margin-left: 8px;
  background: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="10" viewBox="0 0 16 10"><path fill="%2300AEEF" d="M 0 0 l 8 10 8 -10 z"/></svg>') no-repeat 100% 50%/cover;
  width: 16px;
  height: 10px;
  transition: all .5s;
}

.period-select-label.active::after {
  transform: rotate(180deg);
}

.period-select-default-dropdown {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
  opacity: 0;
}

.period-select-custom-dropdown {
  position: absolute;
  top: 3rem;
  left: 0;
  z-index: 3;
  padding: 1rem 0;
  border-radius: 16px;
  box-shadow: 8px 8px 32px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border: solid 1px #f5f5f5;
  font-size: 18px;
  font-weight: 600;
  color: #00aeef;
}

.period-select-custom-dropdown div {
  padding: .5rem 1rem;
  cursor: pointer;
}

.period-select-custom-dropdown div:hover {
  background: #e8f9ff;
}

.projects-list {
  margin: 0;
}

.projects-list .projects-list-item {
  padding: 0;
}

.projects-list .call-to-action {
  min-height: 126px;
}

.projects-list .link-with-arrow {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  max-width: none;
  align-items: center;
  text-decoration: none;
  padding: 0;
  margin: 0;
  color: #000;
  font-weight: normal;
}

@media (min-width: 768px) {
  .projects-list .link-with-arrow {
    padding: 48px;
  }
}

.projects-list .project-preview {
  position: relative;
}

.projects-list .project-preview .project-meta {
  display: none;
}

.projects-list .project-preview:hover .project-meta {
  display: block;
}

.projects-list .project-meta {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  font-size: 1.3rem;
  color: #00aeef;
}

@media (min-width: 768px) {
  .projects-list .project-meta {
    font-size: 2rem;
  }
}

.projects-list .project-meta small {
  display: block;
  color: white;
  font-size: 1rem;
}

.projects-list .project-meta-inner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 24px;
}

.projects-list .project-meta-inner a {
  display: block;
  width: 100%;
  height: 100%;
}

.process-page h1 {
  max-width: 832px;
}

.process-page h2 {
  max-width: none;
}

.process-items-block {
  margin-top: 3rem;
  padding: 7rem 0 4rem;
  background: #ebf9fe;
}

.process-items-block .container {
  position: relative;
}

.process-items-block .speechbubble {
  margin: -9rem 0 4rem;
}

@media (min-width: 576px) {
  .process-items-block .speechbubble {
    margin: 0;
    position: absolute;
    top: -10rem;
    right: 0;
  }
}

.process-list li {
  margin-bottom: 3rem;
}

.process-list a {
  display: block;
  padding-top: 72px;
  text-align: center;
  font-weight: 600;
}

.process-list a::before {
  display: block;
  content: "";
  position: absolute;
  left: 50%;
  top: 0;
  width: 68px;
  height: 64px;
  transform: translateX(-50%);
  background: transparent no-repeat 0 0 / 64px 64px;
}

.process-list a:hover {
  text-decoration: none;
  opacity: .6;
}

.process-list .analytics::before {
  background-image: url(/i/icons/analytics.svg);
}

.process-list .prototyping::before {
  background-image: url(/i/icons/prototyping.svg);
}

.process-list .design::before {
  background-image: url(/i/icons/design.svg);
}

.process-list .development::before {
  background-image: url(/i/icons/development.svg);
}

.process-list .testing::before {
  background-image: url(/i/icons/testing.svg);
}

.process-list .support::before {
  background-image: url(/i/icons/support.svg);
}

.process-descriptions-block article {
  padding: 2rem 0;
}

.process-descriptions-block .big-number {
  margin-right: 4rem;
  font-size: 24px;
  font-weight: 800;
}

@media (min-width: 768px) {
  .process-descriptions-block .big-number {
    font-size: 120px;
  }
}

.process-descriptions-block h2 {
  margin: 6px 0 1rem;
}

@media (min-width: 768px) {
  .process-descriptions-block h2 {
    margin: 4rem 0 1rem;
  }
}

.process-descriptions-block .related-projects {
  margin: 4rem 0 0;
}

.process-descriptions-block .project-image img {
  border-radius: 30px;
  transition: all 0.3s;
}

.process-descriptions-block .project-image img:hover {
  border-radius: 15px;
}

.process-descriptions-block .project-meta {
  display: none;
}

.process-descriptions-block .link-with-arrow {
  color: #000;
  font-weight: normal;
}

.additional-services-block {
  padding: 4rem 0;
  background: #f5f5f5;
}

.additional-services-block h2 {
  font-size: 24px;
  margin-bottom: 3rem;
}

@media (min-width: 576px) {
  .additional-services-block h2 {
    font-size: 32px;
  }
}

@media (min-width: 768px) {
  .additional-services-block h2 {
    font-size: 40px;
  }
}

@media (min-width: 992px) {
  .additional-services-block h2 {
    font-size: 40px;
  }
}

@media (min-width: 1200px) {
  .additional-services-block h2 {
    font-size: 48px;
  }
}

.additional-services-block b {
  font-weight: 600;
}

.contact-us-block {
  padding: 4rem 0;
}

.contact-us-block h2 {
  font-size: 24px;
}

@keyframes vacanciesBannerAnimation1 {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes vacanciesBannerAnimation2 {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes vacanciesBannerAnimation3 {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes vacanciesBannerAnimation4 {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.vacancies-page .banner-wrapper {
  position: relative;
  width: 100%;
  height: 200px;
}

@media (min-width: 576px) {
  .vacancies-page .banner-wrapper {
    height: 260px;
  }
}

@media (min-width: 768px) {
  .vacancies-page .banner-wrapper {
    height: 320px;
  }
}

@media (min-width: 992px) {
  .vacancies-page .banner-wrapper {
    height: 424px;
  }
}

@media (min-width: 1200px) {
  .vacancies-page .banner-wrapper {
    height: 600px;
  }
}

.vacancies-page .banner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.vacancies-page .banner:nth-child(1) {
  background-image: url(/i/photos/office/slide1.jpg);
  animation: 12s 3s infinite vacanciesBannerAnimation1;
}

.vacancies-page .banner:nth-child(2) {
  background-image: url(/i/photos/office/slide2.jpg);
  animation: 12s 3s infinite vacanciesBannerAnimation2;
}

.vacancies-page .banner:nth-child(3) {
  background-image: url(/i/photos/office/slide3.jpg);
  animation: 12s 3s infinite vacanciesBannerAnimation3;
}

.vacancies-page .banner:nth-child(4) {
  background-image: url(/i/photos/office/slide4.jpg);
  animation: 12s 3s infinite vacanciesBannerAnimation4;
}

.vacancies-page .banner:nth-child(1) {
  opacity: 1;
}

.vacancies-page .banner:nth-child(3) {
  background-position: center top;
}

.vacancies-page hr {
  margin: 3rem 0;
}

.vacancies-page h1 {
  max-width: 500px;
  margin-bottom: 3rem;
}

.vacancies-page h2 {
  max-width: 600px;
  margin: 3rem 0 2rem;
  font-size: 20px;
  font-weight: bold;
}

@media (min-width: 576px) {
  .vacancies-page h2 {
    font-size: 2.667rem;
  }
}

.vacancies-page h3 {
  font-size: 16px;
  font-weight: bold;
}

@media (min-width: 576px) {
  .vacancies-page h3 {
    font-size: 20px;
  }
}

@media (min-width: 768px) {
  .vacancies-page h3 {
    font-size: 20px;
  }
}

@media (min-width: 992px) {
  .vacancies-page h3 {
    font-size: 20px;
  }
}

@media (min-width: 1200px) {
  .vacancies-page h3 {
    font-size: 24px;
  }
}

.vacancies-header {
  position: relative;
}

.vacancies-header .speechbubble {
  position: absolute;
  top: -6.5rem;
  right: 0;
}

@media (min-width: 576px) {
  .vacancies-header .speechbubble {
    top: -8rem;
  }
}

.vacancies-filter {
  margin: 2rem 0;
}

.vacancies-list {
  margin: 2rem 0;
}

.vacancies-list .vacancies-list-item {
  padding: 0 2rem .7rem 0;
}

.vacancies-list a {
  color: #1c273a;
}

.vacancies-list a:hover, .vacancies-list a:active, .vacancies-list a:focus {
  color: #00aeef;
  text-decoration: none;
}

.vacancies-perks-block {
  background: url(/i/bg/paragraph.svg) no-repeat 100% 50%/contain;
}

.vacancies-perks-list li {
  margin-bottom: 2rem;
}

@media (min-width: 768px) {
  .vacancies-perks-list p {
    padding-right: 2rem;
  }
}

.vacancies-photos-block .row > div {
  margin-bottom: 2rem;
}

@media (min-width: 768px) {
  .vacancies-photos-block img {
    max-height: 158px;
  }
}

@media (min-width: 992px) {
  .vacancies-photos-block img {
    max-height: 218px;
  }
}

@media (min-width: 1200px) {
  .vacancies-photos-block img {
    max-height: 260px;
  }
}

.vacancy-page h1 {
  margin-bottom: 3rem;
}

.vacancy-page h2, .vacancy-page h3 {
  margin: 2.5rem 0 1.2rem;
}

.vacancy-page ul {
  max-width: 832px;
}

.vacancy-page form {
  margin-bottom: 3rem;
}

.vacancy-author-quote {
  margin: 0 1rem 4rem;
}

@media (min-width: 576px) {
  .vacancy-author-quote {
    margin: 0 3rem 4rem;
  }
}

@media (min-width: 768px) {
  .vacancy-author-quote {
    margin: 0 4rem 4rem;
  }
}

.vacancy-author-quote .speechbubble {
  max-width: none;
}

.vacancy-author-quote .author {
  margin: 1rem 0;
  font-size: 14px;
}

.vacancy-author-quote .author img {
  max-width: 72px;
  border-radius: 50%;
  border: 1px solid #f5fcfe;
}

.vacancy-author-quote .author .name {
  display: block;
  font-weight: 600;
}

.vacancy-author-quote .author .position {
  display: block;
}

.vacancy-instruments {
  margin: 3rem 0 0;
  padding: 0 1rem;
  background-color: #1c273a;
  color: #fff;
}

@media (min-width: 576px) {
  .vacancy-instruments {
    padding: 0;
  }
}

.vacancy-instruments .icon {
  margin-bottom: 3rem;
  text-align: center;
}

.vacancy-instruments .icon img {
  display: block;
  margin: 0 auto 2rem;
  height: 80px;
  width: auto;
  max-width: 100%;
}

.vacancy-instruments .container {
  padding: 5rem 0 2rem;
  position: relative;
}

.vacancy-instruments-bubble {
  position: static;
  margin: -3rem 0 4rem auto;
  max-width: 624px;
}

.vacancy-conditions {
  margin: 3rem 0;
  padding: .5rem 0 2.5rem;
  background-color: #ebf9fe;
}

.vacancy-form {
  padding: 2rem 0;
}

.vacancy-form .btn {
  min-width: 280px;
  width: 100%;
}

@media (min-width: 576px) {
  .vacancy-form .btn {
    width: auto;
  }
}

.vacancy-form-header p {
  font-weight: 600;
}

.vacancy-form-header h2 {
  margin: 1rem 0 2rem;
  font-size: 20px;
}

@media (min-width: 576px) {
  .vacancy-form-header h2 {
    font-size: 2.667rem;
    max-width: 80%;
  }
}
