.about-quote-block {
  .container {
    overflow: hidden;
    @include md {
      overflow: visible;
    }
  }
}
.about-quote-block.vacancies {
  .large-quote {
    margin-top: 0;
  }
}
.large-quote {
  position: relative;
  margin-top: 4rem;
  padding-bottom: 0;
  @include md {
    margin-top: 8rem;
    padding-bottom: 4rem;
  }
  .speechbubble {
    position: relative;
    z-index: 1;
    max-width: none;
    @include md {
      max-width: 60%;
      margin-right: auto;
      margin-left: 0;
    }
    @include lg {
      margin-right: 0;
      margin-left: auto;
    }
  }
  .author {
    position: relative;
    z-index: 1;
    margin-top: 6rem;
    margin: 6rem 0 0;
    font-size: 14px;
    span {
      display: block;
    }
    @include md {
      max-width: 60%;
      margin: 2rem auto 0 0;
    }
    @include lg {
      margin: 2rem 0 0 auto;
    }
  }
  .author-photo {
    position: relative;
    z-index: -1;
    margin: -8rem -2rem 0 0;
    text-align: right;
    img {
      width: auto;
      height: auto;
      max-height: 200px;
    }
    @include md {
      position: absolute;
      max-width: 50%;
      margin: 0;
      left: auto;
      right: -4rem;
      bottom: 0;
      img {
        max-width: 100%;
        max-height: none;
      }
    }
    @include lg {
      margin-left: 0;
      left: -6rem;
      right: auto;
    }
  }
  .name {
    font-weight: 600;
  }
}
.large-quote.large-quote-reversed {
  .speechbubble {
    @include md {
      margin-right: auto;
      margin-left: 0;
    }
  }
  .author {
    margin-left: 0;
    margin-right: auto;
    @include md {
      text-align: right;
    }
  }
  .author-photo {
    @include md {
      bottom: -3rem;
    }
    @include lg {
      left: auto;
      right: -8rem;
    }
  }
}

.about-achievements-block {
  padding: 2rem 0;
  @include sm {
    padding: 4rem 0;
  }
  background-color: $altBgColor;
  color: #fff;
  li {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  b {
    display: block;
    margin-bottom: .5rem;
    @include fs(24px, $xl: 40px);
    font-weight: bold;
  }
  .dz-logo {
    max-width: 192px;
  }
  .carousel-inner {
    min-height: 440px;
  }
}

.about-process-block {
  padding: 4rem 0 2rem;
  background-color: $mutedBgColor;
  li {
    margin-bottom: 2rem;
  }
  p:last-child {
    margin-bottom: 0;
  }
}

.call-to-action {
  padding: 4rem 0;
  p {
    font-weight: 600;
  }
  h2 {
    @include fs(20px, 24px, 24px, 24px, 40px);
  }
}

.activities-block {
  padding-top: 4rem;
  background: url(/i/bg/activities.svg) no-repeat 65% 50% / contain;
  h2 {
    @include fs(20px, 24px, 40px, 40px, 48px);
    max-width: 624px;
  }
  .carousel {
    margin: 2rem;
    @include md {
      margin: 4rem 5rem;
    }
  }
  .carousel-inner {
    min-height: 210px;
    @include md {
      min-height: 300px;
    }
  }
}

.vacancies-link {
  position: relative;
  z-index: 2;
  margin-bottom: 2rem;
  @include md {
    margin: -9rem 0 2rem;
  }
}
.about-page .vacancies-photos-block {
  padding-top: 1px; // uncollapse heading margin
  background-color: $mutedBgColor;
}

.about-timeline {
  margin: 4rem 0 4rem 2rem;
  @include sm {
    margin: 4rem 0 4rem 6rem;
  }
  @include lg {
    margin: 4rem 0 12rem;
  }
  dl {
    position: relative;
    display: flex;
    padding-bottom: 4rem;
    flex-direction: column;
    @include lg {
      padding-bottom: 0;
      flex-direction: row;
    }
    &::after {
      // the line
      content: "";
      display: block;
      position: absolute;
      opacity: .2;
      top: 22px;
      right: auto;
      bottom: 0;
      left: 0;
      width: 1px;
      height: auto;
      background-image: linear-gradient(to bottom, #00aeef, #00aeef 86%, rgba(0, 174, 239, 0));
      @include lg {
        top: auto;
        right: 0;
        bottom: -30px;
        left: 39px;
        width: auto;
        height: 1px;
        background-image: linear-gradient(to right, #00aeef, #00aeef 86%, rgba(0, 174, 239, 0));
      }
    }
    &::before {
      // the flag
      content: "";
      display: block;
      position: absolute;
      width: 16px;
      height: 16px;
      background: url(/i/icons/flag.svg) no-repeat 0 0 / 16px 16px;
      top: 8px;
      bottom: auto;
      left: 0;
      @include lg {
        top: auto;
        bottom: -30px;
        left: 39px;
      }
    }
  }
  div {
    position: relative;
    margin: .5rem 0 .5rem 3rem;
    @include lg {
      margin: 0 .8rem;
    }
  }
  dt {
    position: relative;
    display: flex;
    @include lg {
      align-items: flex-end;
      height: 70px;
      line-height: 70px;
    }
    @include xl {
      height: 120px;
      line-height: 120px;
    }
    color: $themeColorMuted;
    &::after {
      // dot on the line
      content: "";
      display: block;
      position: absolute;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: #ffffff;
      border: solid 1px $themeColor;
      top: 50%;
      bottom: auto;
      left: -3rem;
      transform: translate(-50%, -50%);
      @include lg {
        top: auto;
        bottom: -30px;
        left: 50%;
        transform: translate(-50%, 50%);
      }
    }
    span {
      transition: all 0.4s;
      line-height: normal;
      font-size: 20px;
    }
  }
  div:first-child dt::after {
    // first dot is always opaque
    background-color: $themeColor;
  }
  div:nth-last-child(3),
  div:nth-last-child(2),
  div:nth-last-child(1) {
    dd {
      right: 0;
    }
  }
  .active dt {
    color: $themeColor;
    @include xl {
      font-size: 110px;
    }
    &::after {
      width: 16px;
      height: 16px;
      border: 0 none;
      background-color: $themeColor;
    }
    &::before {
      content: "";
      display: block;
      position: absolute;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background-color: $themeColor;
      opacity: .1;
      bottom: 50%;
      left: -3rem;
      transform: translate(-50%, 50%);
      @include lg {
        bottom: -30px;
        left: 50%;
        transform: translate(-50%, 50%);
      }
    }
    span {
      font-size: 80px;
      @include lg {
        line-height: 70px;
      }
      @include xl {
        line-height: 90px;
        font-size: 110px;
      }
    }
  }
  dd {
    @include lg {
      position: absolute;
      bottom: -100px;
      white-space: nowrap;
    }
  }
}
