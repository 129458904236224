$vacanciesBanners: 4;

@for $i from 0 through $vacanciesBanners - 1 {
  @keyframes vacanciesBannerAnimation#{$i+1} {
    @for $j from 0 through $vacanciesBanners {
      #{100/$vacanciesBanners * $j}% {
        @if $i == $j or ($i == 0 and $j == $vacanciesBanners) {
          opacity: 1;
        } @else {
          opacity: 0;
        }
      }
    }
  }
}

.vacancies-page {
  .banner-wrapper {
    position: relative;
    width: 100%;
    height: 200px;
    @include sm {
      height: 260px;
    }
    @include md {
      height: 320px;
    }
    @include lg {
      height: 424px;
    }
    @include xl {
      height: 600px;
    }
  }
  .banner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    @for $i from 1 through $vacanciesBanners {
      &:nth-child(#{$i}) {
        background-image: url(/i/photos/office/slide#{$i}.jpg);
        animation: $vacanciesBanners * 3s 3s infinite vacanciesBannerAnimation#{$i};
      }
    }
    &:nth-child(1) {
      opacity: 1;
    }
    &:nth-child(3) {
      background-position: center top;
    }
  }
  hr {
    margin: 3rem 0;
  }
  h1 {
    max-width: 500px;
    margin-bottom: 3rem;
  }
  h2 {
    max-width: 600px;
    margin: 3rem 0 2rem;
    font-size: 20px;
    @include sm {
      font-size: 2.667rem;
    }
    font-weight: bold;
  }
  h3 {
    @include fs(16px, 20px, 20px, 20px, 24px);
    font-weight: bold;
  }
}

.vacancies-header {
  position: relative;
  .speechbubble {
    position: absolute;
    top: -6.5rem;
    @include sm {
      top: -8rem;
    }
    right: 0;
  }
}
.vacancies-filter {
  margin: 2rem 0;
}
.vacancies-list {
  margin: 2rem 0;
  .vacancies-list-item {
    padding: 0 2rem .7rem 0;
  }
  a {
    color: $textColor;
    &:hover, &:active, &:focus {
      color: $linkColor;
      text-decoration: none;
    }
  }
}
.vacancies-perks-block {
  background: url(/i/bg/paragraph.svg) no-repeat 100% 50% / contain;
}
.vacancies-perks-list {
  li {
    margin-bottom: 2rem;
  }
  p {
    @include md {
      padding-right: 2rem;
    }
  }
}
.vacancies-photos-block {
  .row > div {
    margin-bottom: 2rem;
  }
  img {
    @include md {
      max-height: 158px;
    }
    @include lg {
      max-height: 218px;
    }
    @include xl {
      max-height: 260px;
    }
  }
}

.vacancy-page {
  h1 {
    margin-bottom: 3rem;
  }
  h2, h3 {
    margin: 2.5rem 0 1.2rem;
  }
  ul {
    max-width: 832px;
  }
  form {
    margin-bottom: 3rem;
  }
}

.vacancy-author-quote {
  margin: 0 1rem 4rem;
  @include sm {
    margin: 0 3rem 4rem;
  }
  @include md {
    margin: 0 4rem 4rem;
  }
  .speechbubble {
    max-width: none;
  }
  .author {
    margin: 1rem 0;
    font-size: 14px;
    img {
      max-width: 72px;
      border-radius: 50%;
      border: 1px solid #f5fcfe;
    }
    .name {
      display: block;
      font-weight: 600;
    }
    .position {
      display: block;
    }
  }
}

.vacancy-instruments {
  margin: 3rem 0 0;
  padding: 0 1rem;
  @include sm {
    padding: 0;
  }
  background-color: $altBgColor;
  color: #fff;
  .icon {
    margin-bottom: 3rem;
    text-align: center;
    img {
      display: block;
      margin: 0 auto 2rem;
      height: 80px;
      width: auto;
      max-width: 100%;
    }
  }
  .container {
    padding: 5rem 0 2rem;
    position: relative;
  }
}
.vacancy-instruments-bubble {
  position: static;
  margin: -3rem 0 4rem auto;
  max-width: 624px;
}

.vacancy-conditions {
  margin: 3rem 0;
  padding: .5rem 0 2.5rem;
  background-color: #ebf9fe;
}

.vacancy-form {
  padding: 2rem 0;
  .btn {
    min-width: 280px;
    width: 100%;
    @include sm {
      width: auto;
    }
  }
}
.vacancy-form-header {
  p {
    font-weight: 600;
  }
  h2 {
    margin: 1rem 0 2rem;
    font-size: 20px;
    @include sm {
      font-size: 2.667rem;
      max-width: 80%;
    }
  }
}
