.page-footer {
  position: relative;
  background: $altBgColor;
  color: #888;
  font-size: 14px;
  font-weight: 600;

  .nav-link {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .social-btn-list {
    path {
      fill: #fff;
    }
    a {
      display: block;
      margin: 0 4px;
      @include sm {
        margin: 0 8px;
      }
      width: 32px;
      height: 32px;
      border-radius: 16px;
      background: transparent;
      text-align: center;
      transition: background 0.5s;
      svg {
        margin-top: 4px;
      }
      &:hover, &:active, &:focus {
        background: $themeColor;
      }
    }
  }
}

.footer-nav {
  padding: 1rem 0;
  &.navbar-dark .navbar-nav .nav-link {
    color: #fff;
    &:hover, &:active, &:focus {
      color: $themeColor;
    }
  }
}
