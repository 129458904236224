.contacts-page {
  .page-title {
    position: relative;
    padding-top: 2rem;
    strong {
      font-weight: 600;
    }
    h1 {
      margin: 1rem 0 2rem;
      @include sm {
        margin-bottom: 8rem;
      }
    }
    .speechbubble {
      max-width: 100%;
      margin-bottom: 2rem;
      @include md {
        margin-bottom: 0;
        max-width: 424px;
      }
      @include sm {
        position: absolute;
        bottom: -11rem;
        right: 0;
        z-index: 2;
      }
    }
  }
}

.map {
  .map-container {
    width: 100%;
    height: 400px;
  }
}

.map-addresses {
  li {
    margin-top: 1rem;
    @include sm {
      margin-top: 4rem;
    }
  }
  address {
    position: relative;
    margin-left: 40px;
    @include sm {
      margin-left: 8px;
    }
    &::before {
      display: block;
      content: "";
      position: absolute;
      left: -32px;
      top: 0;
      width: 24px;
      height: 24px;
      background: url(/i/icons/map-point.svg) no-repeat 0 0 / 24px 24px;
    }
    &.active::before {
      background-image: url(/i/icons/map-point-active.svg);
    }
  }
  p.address-title {
    margin: 0 0 .5rem;
    font-weight: 600;
    color: $linkColor;
    cursor: pointer;
  }
  p {
    margin: 0;
  }
}
