.page-header {
  @include logoOnWhite();

  position: relative;
  z-index: 9999;
  font-size: 14px;
  font-weight: 600;

  .nav-link {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .link-with-arrow {
    display: flex;
    align-items: center;
    margin: 0;
    svg {
      display: inline-block;
      width: 40px !important;
      margin: 0 0 0 8px;
      @include md {
        display: none;
      }
      @include xl {
        display: inline-block;
      }
    }
    path {
      stroke: #888;
    }
    &:hover, &:active {
      path {
        stroke: $textColor;
        transform: none !important;
      }
    }
  }

  .navbar-nav.contacts {
    font-weight: normal;
    a {
      color: $linkColor;
      &:hover, &:active {
        color: $linkColor;
        opacity: .75;
      }
    }
  }
}

.meta-nav {
  height: 40px;
  padding: 0;
  background: #e6e6e6;
  color: #888;

  a {
    color: #888;
    &:hover, &:active {
      color: $textColor;
    }
  }
  .active a {
    color: $textColor;
  }
  .vs {
    height: 24px;
    margin: 0 12px;
  }
}

.main-nav {
  padding: 0;
  box-shadow: 8px 8px 32px 0 rgba(0, 0, 0, 0.1);
  transition: height 0.3s;

  &.hidden {
    display: block;
    overflow: hidden;
    height: 0;
    transition: none;
    .container {
      overflow: hidden;
      height: 0;
    }
  }

  .main-menu {
    font-weight: 600;
    a {
      opacity: .75;
      @include md {
        opacity: 1;
      }
    }
    .primary-link a {
      opacity: 1;
    }
  }
  .contacts {
    border-left: 0 none;
    li {
      margin-right: 24px;
    }
    .phone {
      width: 24px;
      height: 24px;
      background: url(/i/icons/phone.svg) no-repeat 0 0 / 24px 24px;
      font-size: 0;
    }
    .email {
      width: 24px;
      height: 24px;
      background: url(/i/icons/email.svg) no-repeat 0 0 / 24px 24px;
      font-size: 0;
    }
    @include sm {
      border-left: 1px solid $separatorColor;
      li {
        margin-right: 0;
      }
      .phone, .email {
        width: auto;
        height: auto;
        background: transparent;
        font-size: inherit;
      }
    }
  }

  .navbar-brand {
    display: flex;
    padding: .5rem;
    @include md {
      padding: 1rem 0;
    }
  }

  .primary-link {
    font-size: 18px;
    padding: .3rem 0;
    @include md {
      font-size: 14px;
      padding: 0;
    }
  }

  &.navbar-light {
    background: #fff;
    .main-menu {
      a {
        color: $altBgColor;
        &:hover, &:active {
          color: $linkColor;
        }
      }
      .active a {
        color: $linkColor;
      }
    }
    .link-with-arrow {
      path {
        stroke: $altBgColor;
      }
      &:hover, &:active {
        path {
          stroke: $linkColor;
        }
      }
    }
    .contacts {
      border-color: $separatorColor;
    }
  }

  &.navbar-dark {
    background-color: $altBgColor;
    .main-menu {
      a {
        color: #fff;
        &:hover, &:active {
          color: $linkColor;
        }
      }
      .active a {
        color: $linkColor;
      }
    }
    .link-with-arrow {
      path {
        stroke: #fff;
      }
      &:hover, &:active {
        path {
          stroke: $linkColor;
        }
      }
    }
    .contacts {
      border-color: rgba(255, 255, 255, .1);
    }
  }
}


@include md {
  .navbar-expand-md .hamburger {
    display: none;
  }
}
@include lg {
  .navbar-expand-lg .hamburger {
    display: none;
  }
}
