.error-block {
  position: relative;
  padding: 2rem 0 6rem 0;
  @include md {
    padding: 6rem 0 6rem 0;
  }
  .speechbubble {
    max-width: 312px;
    margin: 0 auto 3rem;
    @include sm {
      transform: translateX(50%);
    }
  }
  h1 {
    max-width: none;
    margin: 1rem auto;
    text-align: center;
    @include fs(80px, $xl: 120px);
  }
  h2 {
    max-width: none;
    margin: 1rem auto;
    text-align: center;
    @include fs(14px, $md: 16px, $xl: 18px);
    font-weight: normal;
  }
}
