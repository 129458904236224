.academy-intro {
  .container {
    position: relative;
  }
  h1 {
    max-width: none;
    @include sm {
      max-width: 50%;
    }
  }
  .speechbubble {
    @include sm {
      position: absolute;
      top: -2rem;
      right: 0;
      max-width: 50%;
    }
  }
}

.academy-about {
  margin-top: 2rem;
  @include sm {
    margin-top: 8rem;
  }
  padding-bottom: 4rem;
  article {
    position: relative;
    margin: 0;
    @include sm {
      margin: 2rem 0;
    }
    padding-top: 48px;
    &::before {
      display: block;
      content: "";
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      @include sm {
        left: 12px;
        transform: none;
      }
      top: 0;
      width: 32px;
      height: 32px;
      background: transparent no-repeat 0 0 / 32px 32px;
    }
  }
  @each $cls in teachers, application, qualification, materials, certificate, comfort, chat, jobs {
    .#{$cls}::before {
      background-image: url(/i/icons/#{$cls}.svg);
    }
  }
  .carousel {
    margin: 3rem 3rem 0;
  }
  .carousel-inner {
    min-height: 140px;
  }
}

.academy-info {
  padding: 2rem 0 3rem;
  @include sm {
    padding: 5rem 0;
  }
  background: $mutedBgColor;

  .btn-group {
    margin: 0 0 3rem;
    .btn {
      padding: 6px 24px;
      height: 46px;
      border-radius: 8px;
    }
    .btn:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    .btn:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  .event-date {
    @include lg {
      margin-top: 8rem;
    }
    b {
      display: block;
      @include fs(20px, 24px, 24px, 40px, 48px);
      font-weight: bold;
    }
  }

  .event-description {
    margin: 2rem 0 0;
  }
}

.course-providers {
  a {
    display: block;
    text-align: center;
  }
  img {
    max-width: 100%;
    max-height: 50px;
    width: auto;
    height: auto;
  }
}

.education-numbers {
  padding: 4rem 0 6rem;
  background: $altBgColor;
  color: #fff;
  h2 {
    margin: 0 0 4rem;
  }
  b {
    display: block;
    @include lg {
      font-size: 40px;
    }
    font-size: 30px;
    font-weight: bold;
  }
}

.digest {
  h1 {
    margin-bottom: 2rem;
    @include lg {
      max-width: 50%;
    }
  }
  p {
    margin-bottom: 3rem;
  }
  .container {
    position: relative;
  }
  .speechbubble {
    margin-top: 2rem;
    @include lg {
      margin-top: 0;
      position: absolute;
      top: -8rem;
      right: 0;
    }
  }
}
.digest-authors {
  font-size: 14px;
  li {
    margin-bottom: 2rem;
  }
  img {
    margin-right: 1rem;
    border-radius: 50%;
  }
  .name {
    font-weight: 600;
  }
}
