.body-events {
  background-color: $altBgColor;
  color: #fff;

  @include logoOnDark();

  .meta-nav {
    background-color: #323c4d;
  }
}

.conferences {
}

.meetups {
  h1 {
    margin-bottom: 2rem;
  }
}

.big-event {
  margin: 2rem 0 4rem;
  img {
    @include lg {
      max-width: 608px;
    }
    @include xl {
      max-width: 832px;
    }
  }
  .event-date {
    margin: 1rem 0;
  }
  .big-number {
    line-height: 1.2;
    margin: 2rem 0;
    b {
      display: block;
      @include fs(24px, $lg: 40px, $xl: 56px);
      font-weight: bold;
    }
  }
  .event-description {
    margin: 2rem 0;
    max-width: 624px;
  }
  .event-logos {
    padding: 0 40px;
    .clients-list {
      path {
        fill: #fff;
      }
    }
    .carousel-inner {
      .row {
        height: 90px;
      }
    }
  }
}

.small-event {
  margin-bottom: 4rem;
  background: #162f48;
  .event-meta {
    padding: 30px;
  }
  .event-description {
    @include fs(20px, $md: 24px);
    font-weight: bold;
    margin-top: 1rem;
  }
}
