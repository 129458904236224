.speechbubble {
  position: relative;
  background: #00aeef;
  box-shadow: 16px 16px 32px 0 rgba(0, 174, 239, 0.4);
  color: #fff;
  letter-spacing: .2px;
  transition: none;

  max-width: none;
  border-radius: 20px;
  padding: 16px 20px;
  font-weight: 300;
  @include sm {
    max-width: 80%;
    padding: 18px 24px;
  }
  @include lg {
    max-width: 50%;
  }
  @include xl {
    border-radius: 40px;
    padding: 28px 32px;
  }

  &.js-speechbubble {
    opacity: 0;
    transform: translate(-10px, -10px);
  }
  &.js-animated, &.shown {
    transition: all 0.8s;
    opacity: 1;
    transform: translate(0, 0);
  }

  p:last-child, p:last-of-type {
    margin-bottom: 0;
  }
  a {
    color: inherit;
    text-decoration: underline;
  }
}
.speechbubble-tl {
  border-top-left-radius: 0;
}
.speechbubble-tr {
  border-top-right-radius: 0;
}
.speechbubble-dl {
  border-bottom-left-radius: 0;
}
.speechbubble-dr {
  border-bottom-right-radius: 0;
}

.speechbubble .likes-container {
  position: absolute;
  right: 0;
  bottom: -2.5rem;
  display: flex;
  cursor: pointer;

  .count {
    margin: 0 0 0 8px;
    color: $themeColor;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
  }
  .heart {
    .contour {
      fill: $themeColor;
    }
    .fill {
      fill: none;
    }
  }
  &:hover {
    .heart {
      .contour {
        fill: none;
      }
      .fill {
        fill: $themeColor;
      }
    }
  }
  &.active {
    .heart {
      animation: 0.2s ease-in-out 0s 1 normal none running likeAnimation;
      .contour {
        fill: none;
      }
      .fill {
        fill: #ff4365;
      }
    }
  }
}
.speechbubble[data-likes] {
  margin-bottom: 3rem;
}

@keyframes likeAnimation {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
